<template>
    <BlankG v-if="screenWidth >= 1000" style="opacity: 0.99;">



        <div class="row" style="padding-top: 5vh;">
            <div class="col-3">
                <div class="set-article-padding">
                    <div class="mb-3">
                        <label for="titleControlInput" class="form-label">标题：</label>
                        <input type="text" class="form-control" id="titleControlInput" v-model="tittle"
                            placeholder="标题不能小于2字符">
                    </div>
                    <div class="mb-3">
                        <label for="formFile" class=" col-form-label">文章封面（可无）：</label>

                        <!-- <div class="row ">
                            <div class="col-8"> -->
                        <input class="form-control" type="file"
                            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg" placeholder="选择文件"
                            id="articleBgFile">
                        <!-- </div> -->
                        <!-- <div class="col-4">
                                <button class="btn btm-m btn-info" @click="update_articleBg(articleId)">提交</button>

                            </div> -->

                        <!-- </div> -->


                    </div>
                    <div class="mb-3">
                        <label for="FormControlTextarea" class="form-label">摘要：</label>
                        <textarea class="form-control" id="FormControlTextarea" rows="3" v-model="brief"
                            placeholder="写文章简介"></textarea>
                    </div>

                    <div class="mb-3">
                        <form>
                            <label class="form-check-label" for="exampleCheck1">是否置顶文章：</label>
                            <input type="checkbox" class="form-check-input" v-model="isTop" id="exampleCheck1">
                        </form>
                    </div>

                    <div class="mb-3">
                        <div>
                            <input type="text" id="examplelabel" style="width: 70%; float: left;" v-model="newLabel">
                            <button @click="addLabel" class="btn btn-sm btn-info" style="width: 30%;">添加标签</button>

                            <!-- <div v-if="myLabels != null"> -->
                            <select class="form-select form-select-sm" v-if="myLabels != null" size="2"
                                style="width: 100%; ">
                                <!-- <option selected>选择已有标签：</option> -->
                                <option v-for="label in   myLabels  " :key="label.id"
                                    @click="setNewLabel(label.labelName)">
                                    {{
        label.labelName }}
                                </option>
                            </select>
                            <!-- <button @click="setNewLabel(label.split('*')[0])" class="btn btn-sm btn-info"
                            style="width: 30%;">添加标签</button> -->


                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="mb-3">

                        <div>
                            <label class="form-check-label" for="exampleCheck2">我的标签：</label>

                            <span v-for="   label   in   labelList  " :key="label.id">
                                <kbd @click="deleteLabel(label)">{{ label }}</kbd>&nbsp;
                            </span>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div v-if="isLoading" class="spinner-border text-primary col-sm-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <button v-else class="btn btn-bg btn-info" style="width: 100%;"
                            @click="upload_article(articleId, tittle, brief, text, isTop, labelList, articleTemp, labelTemp)">发布</button>

                    </div>
                    <div class="mb-3">

                    </div>
                </div>
            </div>
            <div class="col-9">

                <div class="write-article-padding">
                    <label class="form-label">文章编写：</label>
                    <v-md-editor v-model="text" height="70vh" mode="editable" default-show-toc="true"
                        placeholder="在这里编写您的文章"
                        left-toolbar="undo redo clear | emoji | h bold italic strikethrough quote | ul ol table hr | link image code "></v-md-editor>

                </div>


                <!-- <button class="btn btn-sm btn-warning" @click="upload_article(tittle, brief, text, labelList)">存为草稿</button> -->
            </div>

        </div>





    </BlankG>
    <div v-else>
        <div class="mb-3">
            <label for="titleControlInput" class="form-label">标题：</label>
            <input type="text" class="form-control" id="titleControlInput" v-model="tittle" placeholder="标题不能小于2字符">
        </div>
        <div class="write-article-padding">
            <label class="form-label">文章编写：</label>
            <v-md-editor v-model="text" height="50vh" mode="edit" placeholder="在这里编写您的文章"
                left-toolbar="undo redo clear | emoji | h bold italic strikethrough quote | ul ol table hr | link image code "></v-md-editor>
        </div>

        <div class="set-article-padding">

            <div class="mb-3 ">
                <label for="formFile" class=" col-form-label">文章封面（可无）：</label>
                <input class="form-control" type="file" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                    placeholder="选择文件" id="articleBgFile">
            </div>
            <div class="mb-3">
                <label for="FormControlTextarea" class="form-label">摘要：</label>
                <textarea class="form-control" id="FormControlTextarea" rows="3" v-model="brief"
                    placeholder="写文章简介"></textarea>
            </div>
            <div class="mb-3">
                <form>
                    <label class="form-check-label" for="exampleCheck1">是否置顶文章：</label>
                    <input type="checkbox" class="form-check-input" v-model="isTop" id="exampleCheck1">
                </form>
            </div>
            <div class="mb-3">
                <div>
                    <input type="text" id="examplelabel" style="width: 60%; float: left;" v-model="newLabel">
                    <button @click="addLabel" class="btn btn-sm btn-info" style="width: 40%;">添加标签</button>
                    <select class="form-select form-select-sm" v-if="myLabels != null" size="2" style="width: 100%; ">
                        <option v-for="label in   myLabels  " :key="label.id" @click="setNewLabel(label.labelName)">
                            {{
        label.labelName }}
                        </option>
                    </select>

                </div>
            </div>
            <div class="mb-3">
                <div>
                    <label class="form-check-label" for="exampleCheck2">我的标签：</label>

                    <span v-for="   label   in   labelList  " :key="label.id">
                        <kbd @click="deleteLabel(label)">{{ label }}</kbd>&nbsp;
                    </span>
                </div>
            </div>
            <div class="mb-3">
                <div v-if="isLoading" class="spinner-border text-primary col-sm-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <button v-else class="btn btn-bg btn-info" style="width: 100%;"
                    @click="upload_article(articleId, tittle, brief, text, isTop, labelList, articleTemp, labelTemp)">发布</button>

            </div>
            <div class="mb-3">
            </div>
        </div>
        <br>
        <br>
    </div>
</template>

<script>
import BlankG from '@/components/BlankBG.vue';
import { useStore } from 'vuex';
import $ from "jquery"
import router from '@/router';

import { ref } from 'vue';

export default {
    components: {
        BlankG,
    },
    data() {
        return {
            tittle: "",
            text: '',
            brief: "",
            labelSet: new Set(),
            labelList: new Array(),
            newLabel: "",
            isTop: false,
            articleId: 0,
            articleTemp: "",
            labelTemp: [],
            screenWidth: 1024,
            authorId: 0,
            myLabels: []

        };
    },

    mounted() {
        const that = this
        const articleId = this.$route.query.articleId

        that.articleId = this.$route.query.articleId
        this.screenWidth = window.innerWidth
        const store = useStore();




        $.ajax({
            url: store.state.BaseUrl + "/api/article/" + articleId,
            type: "GET",
            headers: {
                Authorization: "Bearer " + store.state.user.token
            },
            success(resp) {

                if (resp.code == 1) {
                    that.authorId = resp.content.authorId
                    that.text = resp.content.content
                    that.tittle = resp.content.tittle
                    that.brief = resp.content.brief
                    that.isTop = resp.content.top
                    // that.article = resp.content
                    that.articleTemp = resp.content
                    getMyLabels()

                    store.dispatch("getFriendInfo", {
                        friendId: that.authorId,
                        success() {

                        },
                        error() {

                        }

                    })


                }
                else {
                    alert("获取文章失败")
                }


            },
            error() {
                // console.log(resp)
                alert("获取文章失败,请检查网络连接")

            }

        })

        const getMyLabels = () => {
            // if (myLabels.value.length >= 1) return;

            $.ajax({
                url: store.state.BaseUrl + "/api/label/common/list/" + that.authorId,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {
                        that.myLabels = resp.content
                        // console.log(myLabels)
                    }
                    // else {
                    // console.log("拉取个人标签失败")
                    // }
                },
            })
        }

        $.ajax({
            url: store.state.BaseUrl + "/api/label/ " + articleId,
            type: "GET",
            headers: {
                Authorization: "Bearer " + store.state.user.token
            },
            success(resp) {

                if (resp.code == 1) {
                    that.labelSet = new Set(resp.content)
                    that.labelList = resp.content;
                    that.labelTemp = resp.content;
                    console.log(resp.content)
                } else {
                    console.log(resp.content)
                }


            },
            // error() {
            //     console.log("获取标签失败")
            // }


        })



    },
    methods: {

        setNewLabel(label) {
            // console.log("选择：" + label)
            this.newLabel = label
        },

        addLabel() {
            if (this.newLabel == null || this.newLabel.length < 2 || this.newLabel.length > 15) return;
            this.labelSet.add(this.newLabel)
            this.labelList = []
            this.labelSet.forEach((element) => {
                this.labelList.push(element)
            });
            this.newLabel = "";

            // console.log(this.labelList);
        },
        deleteLabel(name) {

            this.labelSet.delete(name)
            this.labelList = []
            this.labelSet.forEach((element) => {
                this.labelList.push(element)
            });
        }
    },

    setup() {
        const store = useStore();
        // let newLabel = ref("");


        const isLoading = ref("")
        isLoading.value = false;
        const drawingBedToken = ref("")
        const articleBgUrl = ref("")


        const get_drawing_bed_token = () => {
            $.ajax({

                url: store.state.BaseUrl + "/api/user/image/token/ ",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                },
                success(resp) {

                    if (resp.code == 1) {
                        drawingBedToken.value = resp.content.token
                    }
                }

            })
        }
        get_drawing_bed_token()


        const update_articleBg = (articleId) => {
            const file = document.getElementById("articleBgFile");

            if (file == null) {
                return;
            }
            const fileOb = file.files[0];


            if (fileOb == null) {

                return
            }
            if (fileOb.length > 5 * 1024 * 1024) {

                alert("文章封面文件过大")
                return
            }

            const params = new FormData();
            params.append('image', fileOb)
            params.append("token", drawingBedToken.value)
            upload_image(params, articleId)
        }

        const upload_image = (params, articleId) => {
            $.ajax({
                url: "https://img.alowlife.com/api/index.php",
                type: "post",
                async: true,
                headers: {
                },
                data: params,
                mimeType: 'multipart/form-data',
                crossDomain: true,
                processData: false,
                contentType: false,
                success(resp) {
                    // console.log(resp)
                    resp = $.parseJSON(resp)
                    if (resp.code == 200) {
                        articleBgUrl.value = resp.url

                        upload_article_bg_url(articleId);
                        alert("文章封面上传成功")
                    } else {
                        alert("文章封面上传失败")
                    }

                },
                error() {
                    alert("文章封面上传失败")

                }

            })
        }

        const upload_article_bg_url = (articleId) => {
            $.ajax({
                url: store.state.BaseUrl + "/api/article/",
                type: "put",
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                data: {
                    "articleId": articleId,
                    "bgUrl": articleBgUrl.value,

                },

                success(resp) {
                    // console.log(articleBgUrl.value)
                    if (resp.message != '成功') {
                        alert("文章图片更新失败")
                        console.log(resp)
                    }
                },
                error() {
                    alert("文章图片更新失败")
                }
            })
        }




        const upload_article = (articleId, tittle, brief, text, isTop, labels, articleTemp, labelTemp) => {
            isLoading.value = true;
            // console.log(articleId, tittle, brief, isTop, labels)
            // const request = {
            //     tittle,
            //     brief,
            //     text
            // }

            if (articleTemp.tittle == tittle && articleTemp.brief == brief && articleTemp.content == text
                && articleTemp.top == isTop && labels == labelTemp) {

                isLoading.value = false;

                alert("好像还没有更改文章")
                return

            }



            if (tittle == null || tittle.length <= 2) {
                alert("标题不能少于两个字符")
                return
            }
            if (text == null || text.length <= 20) {
                alert("文章不能少于20个字符")
                return
            }

            $.ajaxSetup({
                traditional: true
            });

            get_drawing_bed_token()
            update_articleBg(articleId)

            setTimeout(() => {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/",
                    type: "put",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token
                    },
                    data: {
                        "articleId": articleId,
                        "tittle": tittle,
                        "brief": brief,
                        "content": text,
                        "isTop": isTop,
                        "isDraft": false,
                        // "bgUrl": articleBgUrl.value,
                        labels
                    },

                    success(resp) {
                        // console.log(articleBgUrl.value)
                        if (resp.code == 1) {
                            tittle = "",
                                brief = "",
                                text = "",
                                isTop = false,
                                labels = "",
                                alert(resp.content.article)

                            router.push({
                                path: "/article",
                                query: {
                                    authorId: store.state.friend.id,
                                    articleId: articleId
                                }
                            })
                            isLoading.value = false;

                        } else {
                            alert(resp.content.article)
                            isLoading.value = false;
                        }
                    },
                    error() {
                        alert("文章发布失败")
                        isLoading.value = false;
                    }
                })
            }, 4000)

        }





        return {
            upload_article,
            isLoading,
            update_articleBg,
        }
    }


};
</script>
<style scoped>
.set-article-padding {
    /* background-color: aliceblue; */
    /* height: 00px; */
    padding-top: 3vh;
}

.write-article-padding {}
</style>