<template>
    <BlankG style="opacity: 0.99;" v-if="screenWidth >= 1000">
        <div class="row" style="padding-top: 2vh;">
            <div class="col-1"></div>
            <div class="col-10">
                <nav class="navbar navbar-light bg-light" style="border: 1px solid rgb(255, 234, 151);">
                    <div class="container-fluid">
                        <a class="navbar-brand" v-if="byLabel">
                            文章管理：标签为“{{ labelName }}”的文章有 {{ totalCount }} 篇
                        </a>
                        <a class="navbar-brand" v-else-if="byCondition">
                            文章管理：标题含“{{ hasTittle }}”的文章有 {{ totalCount }} 篇
                        </a>
                        <a class="navbar-brand" v-else>文章共有：{{ totalCount }}篇</a>
                        <div class="d-flex">
                            <input v-model="hasTittle" class="form-control me-2" type="search" placeholder="查找文章标题"
                                aria-label="Search">
                            <button @click="search_article_byTittle()" class="btn btn-outline-success"
                                type="button">Search</button>
                        </div>
                    </div>
                </nav>
                <br>
                <div class="list-group">
                    <div v-for="articleItem in articleList" :key="articleItem.id">
                        <a href="#" class="list-group-item list-group-item-action "
                            style="border: 1px solid rgb(255, 234, 151);">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                                <small style="font-size: 10px;">发布： {{ timestampToTime(Date.parse(new
        Date(articleItem.article.createTime).toString())) }}</small>
                                <small style="font-size: 10px;">最后修改： {{ timestampToTime(Date.parse(new
        Date(articleItem.article.updateTime).toString())) }}</small>
                                <small style="font-size: 10px;">作者：<mark>{{ articleItem.author }}</mark></small>
                                <small style="font-size: 10px;">是否草稿：
                                    <mark v-if="articleItem.article.draft">是</mark>
                                    <mark v-else>否</mark>
                                </small>

                            </div>
                            <p class="mb-1">简介：{{ articleItem.article.brief }}</p>
                            <span>
                                <button type="button" @click="confirm_delete_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-danger" style="float: right; margin-left: 3px;">删除</button>
                                <button type="button" @click="modify_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-warning" style="float: right; margin-left: 3px;">修改</button>


                                <button v-if="articleItem.article.draft" type="button"
                                    @click="public_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-success" style="float: right; margin-left: 3px;">发布</button>
                                <button v-else type="button" @click="draft_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-secondary" style="float: right; margin-left: 3px;">草稿</button>
                                <button type="button" @click="show_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>
                            </span>
                            <span>
                                <text style="font-size: 12px; float: left;">
                                    热度：{{ articleItem.article.viewCount }}&nbsp;
                                </text>
                            </span>
                            <span v-for="label in articleItem.labelList" :key="label.id">
                                <text style=" margin-right: 4px; font-size: 12px; float: left;">
                                    <mark @click="search_article_byLabel(label)">{{ label }}</mark>
                                </text>
                            </span>
                            <small>&nbsp;</small>

                        </a>
                    </div>
                </div>
                <br>
                <nav aria-label="Page navigation example">
                    <ul class="pagination  pagination-sm " style="float:right">
                        <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                        </li>
                        <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                            v-for=" page in showPage" :key="page.number">
                            <a class="page-link" href="#">{{ page.number }}</a>
                        </li>
                        <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </BlankG>
    <div v-else style="padding-top: 2vh; padding-left: 2px; padding-right: 2px;">
        <nav class="navbar navbar-light bg-light" style="border: 1px solid rgb(255, 234, 151);">
            <div class="container-fluid">
                <a class="navbar-brand" v-if="byLabel">
                    标签为“{{ labelName }}”的文章有：{{ totalCount }}篇
                </a>
                <a class="navbar-brand" v-else-if="byCondition">
                    标题含“{{ hasTittle }}”的文章有：{{ totalCount }}篇
                </a>
                <a class="navbar-brand" v-else>文章共有：{{ totalCount }}篇</a>
                <div class="d-flex">
                    <input v-model="hasTittle" class="form-control me-2" type="search" placeholder="查找文章标题"
                        aria-label="Search">
                    <button @click="search_article_byTittle()" class="btn btn-outline-success"
                        type="button">Search</button>
                </div>
            </div>
        </nav>
        <br>
        <div class="list-group">
            <div v-for="articleItem in articleList" :key="articleItem.id">
                <a href="#" class="list-group-item list-group-item-action "
                    style="border: 1px solid rgb(255, 234, 151);">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                        <small style="font-size: 10px;">发布： {{ timestampToTime(Date.parse(new
        Date(articleItem.article.createTime).toString())) }}</small>
                        <small style="font-size: 10px;">最后修改： {{ timestampToTime(Date.parse(new
        Date(articleItem.article.updateTime).toString())) }}</small>
                        <small style="font-size: 10px;">作者：<mark>{{ articleItem.author }}</mark></small>
                        <small style="font-size: 10px;">是否草稿：
                            <mark v-if="articleItem.article.draft">是</mark>
                            <mark v-else>否</mark>
                        </small>

                    </div>
                    <p class="mb-1">简介：{{ articleItem.article.brief }}</p>
                    <span>
                        <button type="button" @click="confirm_delete_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-danger" style="float: right; margin-left: 3px;">删除</button>
                        <button type="button" @click="modify_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-warning" style="float: right; margin-left: 3px;">修改</button>


                        <button v-if="articleItem.article.draft" type="button"
                            @click="public_article(articleItem.article.articleId)" class="btn btn-sm btn-success"
                            style="float: right; margin-left: 3px;">发布</button>
                        <button v-else type="button" @click="draft_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-secondary" style="float: right; margin-left: 3px;">草稿</button>
                        <button type="button" @click="show_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>
                    </span>
                    <span>
                        <text style="font-size: 12px; float: left;">
                            热度：{{ articleItem.article.viewCount }}&nbsp;
                        </text>
                    </span>
                    <span v-for="label in articleItem.labelList" :key="label.id">
                        <text style=" margin-right: 4px; font-size: 12px; float: left;">
                            <mark @click="search_article_byLabel(label)">{{ label }}</mark>
                        </text>
                    </span>
                    <small>&nbsp;</small>

                </a>
            </div>
        </div>
        <br>
        <nav aria-label="Page navigation example">
            <ul class="pagination  pagination-sm " style="float:right">
                <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                </li>
                <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                    v-for=" page in showPage" :key="page.number">
                    <a class="page-link" href="#">{{ page.number }}</a>
                </li>
                <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import BlankG from '@/components/BlankBG.vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from "@/router";
export default {
    components: {
        BlankG,
    },
    data() {
        return {
            screenWidth: 1024,
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth
    },
    setup() {
        const store = useStore();

        const articleList = ref([])
        const totalCount = ref([])
        // const PAGE = 6;
        let currentPage = 1;
        let totalPage = 1;
        let showPage = ref([]);
        const byCondition = ref("");
        const byLabel = ref("");
        const hasTittle = ref("")
        const search_article_byTittle = () => {
            byCondition.value = true;
            byLabel.value = false

            pull_page(1);
        }

        const labelName = ref("")
        const search_article_byLabel = (label) => {
            byLabel.value = true
            byCondition.value = false
            labelName.value = label
            pull_page(1);

        }

        const public_article = (articleId) => {
            $.ajaxSetup({
                traditional: true
            });

            $.ajax({
                url: store.state.BaseUrl + "/api/article/",
                type: "put",
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                data: {
                    "articleId": articleId,
                    "idDraft": false,

                },
                success(resp) {
                    if (resp.code == 1) {
                        alert(resp.content.article)
                        router.push({
                            path: "/article",
                            query: {
                                authorId: store.state.user.id,
                                articleId: articleId
                            }
                        })

                    } else {
                        alert(resp.content.article)
                    }
                },
                error() {
                    console.log("文章发布失败")

                }
            })

        }
        const draft_article = (articleId) => {
            $.ajax({
                url: store.state.BaseUrl + "/api/article/draft/" + articleId,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        alert("加入草稿箱成功")
                        pull_page(1)
                    } else {
                        alert("加入草稿箱失败")
                    }
                },
                error() {
                    alert("加入草稿箱失败")
                }
            })
        }

        const show_article = (articleId) => {
            router.push({
                path: "/article",
                query: {
                    authorId: store.state.user.id,
                    articleId: articleId
                }
            })
        }

        const modify_article = (articleId) => {
            router.push({
                path: "/article-modify",
                query: {
                    articleId: articleId
                }
            })
        }

        const confirm_delete_article = (articleId) => {
            let flag;
            flag = confirm("是否确认永久删除，无法恢复")
            if (flag == true) {
                delete_article(articleId);
            }
        }

        const delete_article = (articleId) => {
            $.ajax({
                url: store.state.BaseUrl + "/api/article/delete/" + articleId,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {
                        alert("删除成功")
                        pull_page(1)
                    } else {
                        alert("删除失败")
                    }
                },
                error() {
                    alert("删除失败")
                },
            })
        }


        const update_pages = () => {
            let max_pages = totalPage;
            console.log("最大页数" + max_pages)
            let new_page = [];
            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }

            showPage.value = new_page;

        }

        const click_page = (page) => {
            if (page === -1) {
                page = currentPage + 1;
            } else if (page === -2) {
                page = currentPage - 1;
            }
            let max_pages = totalPage;
            if (page >= 1 && page <= max_pages) {
                console.log("获取页面：" + page)
                pull_page(page);
            }
        }

        const pull_page = (page) => {
            currentPage = page;

            if (byLabel.value) {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/common/list/byLabel/" + labelName.value + "/" + "draftAndNonDraft/" + 0 + "/" + currentPage,
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },

                    success(resp) {

                        if (resp.code == 1) {
                            articleList.value = resp.content.articleList;
                            totalPage = resp.content.totalPage;
                            totalCount.value = resp.content.totalCount

                            update_pages();
                            console.log("拉取 " + labelName.value + " 文章列表成功")
                        }



                    },

                })
            }
            else if (byCondition.value) {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/list/condition",
                    type: "post",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    data: {
                        hasTittle: hasTittle.value,
                        hasAuthorId: 0,
                        pageNum: page,
                        hasDraft: "all"

                    },

                    success(resp) {

                        articleList.value = resp.content.articleList;
                        console.log(resp);
                        totalPage = resp.content.totalPage;
                        totalCount.value = resp.content.totalCount

                        update_pages();
                        console.log("拉取 " + hasTittle.value + " 文章列表成功")


                    },

                })
            } else {
                $.ajax({
                    url: store.state.BaseUrl + "/api/admin/articles/list/" + page + "/all",
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },

                    success(resp) {

                        articleList.value = resp.content.articleList;
                        console.log(resp);
                        totalCount.value = resp.content.totalCount
                        totalPage = resp.content.totalPage;
                        update_pages();
                        console.log("拉取文章列表成功")


                    },

                })
            }
        }

        const timestampToTime = (timestamp) => {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }

        pull_page(currentPage)
        return {
            articleList,
            click_page,
            showPage,
            show_article,
            delete_article,
            modify_article,
            public_article,
            draft_article,
            confirm_delete_article,
            search_article_byTittle,
            hasTittle,
            timestampToTime,
            search_article_byLabel,
            byCondition,
            byLabel,
            labelName,
            totalCount
        }





    }



};

</script>

<style scoped>
.article-list-padding {
    height: 50vh;
    width: 55vw;
    position: fixed;
    overflow-y: scroll;
}
</style>