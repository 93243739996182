<template>
    <BlankG style="opacity: 0.99;" v-if="screenWidth >= 1000">

        <div class="row" style="padding-top: 2vh;">

            <div class="col-1"></div>
            <div class="col-10">
                <!-- <div style="height: 7vh; width: 100%; border: solid 1px black;">
                     -->
                <nav class="navbar navbar-light bg-light" style="border: 1px solid rgb(255, 234, 151);">
                    <div class="container-fluid">
                        <!-- <a class="navbar-brand">文章管理</a> -->
                        <a class="navbar-brand" v-if="byLabel">
                            我的文章：标签为“{{ labelName }}”的文章有 {{ totalCount }}
                        </a>
                        <a class="navbar-brand" v-else-if="byCondition">
                            我的文章：标题含“{{ hasTittle }}”的文章有 {{ totalCount }}
                        </a>
                        <a class="navbar-brand" v-else>文章管理</a>

                        <!-- <form onsubmit="return false"> -->
                        <div class="d-flex">
                            <input v-model="hasTittle" class="form-control me-2" type="search" placeholder="查找文章标题"
                                aria-label="Search">
                            <button @click="search_article_byTittle()" class="btn btn-outline-success"
                                type="button">Search</button>
                        </div>
                    </div>
                </nav>
                <br>
                <!-- <div class="article-list-padding">
                    <ul class="list-group">
                    <li class="list-group-item" v-for="article in articleList" :key="article.id">发布时间：{{ new Date(article.createTime).toLocaleDateString() }}
                        标题：{{ article.tittle }}热度：{{ article.viewCount }} 是否置顶：{{ article.top }}
                    
                    </li>
                    </ul>
                </div> -->



                <div class="list-group">
                    <!-- <a href="#" class="list-group-item list-group-item-action active" aria-current="true"> -->
                    <div v-for="articleItem in articleList" :key="articleItem.id">
                        <a href="#" class="list-group-item list-group-item-action "
                            style="border: 1px solid rgb(255, 234, 151);">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                                <!-- <small>{{ new Date(article.createTime).toLocaleDateString() }}</small> -->
                                <small>创建时间： {{ timestampToTime(Date.parse(new
                                    Date(articleItem.article.createTime).toString())) }}</small>

                            </div>
                            <p class="mb-1">简介：{{ articleItem.article.brief }}</p>
                            <!-- <hr> -->





                            <span>

                                <button type="button" @click="confirm_delete_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-danger" style="float: right; margin-left: 3px;">删除</button>
                                <!-- <button style="float: right;">加入草稿</button> -->
                                <button type="button" @click="modify_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-warning" style="float: right; margin-left: 3px;">修改</button>
                                <button type="button" @click="draft_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-success" style="float: right; margin-left: 3px;">草稿</button>
                                <button type="button" @click="show_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>

                                <!-- <button style="float: right;">查看</button> -->
                            </span>


                            <!-- <small>热度：{{ articleItem.article.viewCount }}
                                <span v-for="label in articleItem.labelList" :key="label.id" style="float: left; ">
                                    <text style=" margin-right: 4px;">
                                        
                                        <mark @click="search_article_byLabel(label)">{{ label }}</mark>

                                    </text>

                                </span>
                                <br>

                            </small> -->
                            <span>
                                <text style="font-size: 12px; float: left;">
                                    热度：{{ articleItem.article.viewCount }}&nbsp;
                                </text>
                            </span>
                            <span v-for="label in articleItem.labelList" :key="label.id">
                                <text style=" margin-right: 4px; font-size: 12px; float: left;">
                                    <mark @click="search_article_byLabel(label)">{{ label }}</mark>
                                </text>
                            </span>
                            <small>&nbsp;</small>

                        </a>
                    </div>

                </div>
                <br>
                <nav aria-label="Page navigation example">
                    <ul class="pagination  pagination-sm " style="float:right">
                        <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                        </li>
                        <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                            v-for=" page in showPage" :key="page.number">
                            <a class="page-link" href="#">{{ page.number }}</a>
                        </li>

                        <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                        </li>
                    </ul>
                </nav>

            </div>

        </div>


    </BlankG>
    <div v-else>

        <div style="padding: 1vw;">
            <nav class="navbar navbar-light bg-light" style="border: 1px solid rgb(255, 234, 151);">
                <div class="container-fluid">
                    <!-- <a class="navbar-brand">文章管理</a> -->
                    <a class="navbar-brand" v-if="byLabel">
                        标签为“{{ labelName }}”的文章有：
                    </a>
                    <a class="navbar-brand" v-else-if="byCondition">
                        标题含“{{ hasTittle }}”的文章有：
                    </a>
                    <a class="navbar-brand" v-else>文章管理</a>

                    <!-- <form onsubmit="return false"> -->
                    <div class="d-flex">
                        <input v-model="hasTittle" class="form-control me-2" type="search" placeholder="查找文章标题"
                            aria-label="Search">
                        <button @click="search_article_byTittle()" class="btn btn-outline-success"
                            type="button">Search</button>
                    </div>
                </div>
            </nav>
        </div>
        <!-- <div style="padding: 1vw;">
            <div class="d-flex">
                <input v-model="hasTittle" class="form-control me-2" type="search" placeholder="查找文章标题" aria-label="Search">
                <button @click="search_article_byTittle()" class="btn btn-success" type="button">Search</button>
            </div>
            <a class="navbar-brand" v-if="byLabel">
                标签为“{{ labelName }}”的文章有：
            </a>
            <a class="navbar-brand" v-else-if="byCondition">
                标题含“{{ hasTittle }}”的文章有：
            </a>
            <a class="navbar-brand" v-else>文章管理：</a>


        </div> -->
        <div class="list-group" style="padding: 1vw;">
            <div v-for="articleItem in articleList" :key="articleItem.id">
                <a href="#" class="list-group-item list-group-item-action " style="border: 1px solid rgb(255, 234, 151);">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                        <p style="font-size: 10px;">{{ timestampToTime(Date.parse(new
                            Date(articleItem.article.createTime).toString())) }}</p>

                    </div>
                    <div>
                        <p class="mb-1" style="font-size: 12px;">简介：{{ articleItem.article.brief }}</p>

                        <p class="mb-1" v-for="label in articleItem.labelList" :key="label.id" style=" ">
                            <text style=" margin-right: 4px; float: left; font-size: 12px;">
                                <mark @click="search_article_byLabel(label)">{{ label }}</mark>

                            </text>

                        </p>
                    </div>
                    <br>
                    <span>

                        <button type="button" @click="confirm_delete_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-danger" style="float: right; margin-left: 3px;">删除</button>
                        <button type="button" @click="modify_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-warning" style="float: right; margin-left: 3px;">修改</button>
                        <button type="button" @click="draft_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-success" style="float: right; margin-left: 3px;">草稿</button>
                        <button type="button" @click="show_article(articleItem.article.articleId)"
                            class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>

                    </span>


                    <p style="font-size: 10px;">热度：{{ articleItem.article.viewCount }}

                        <br>

                    </p>

                </a>
            </div>

        </div>
        <nav aria-label="Page navigation example">
            <ul class="pagination  pagination-sm " style="float:right">
                <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                </li>
                <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                    v-for=" page in showPage" :key="page.number">
                    <a class="page-link" href="#">{{ page.number }}</a>
                </li>

                <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                </li>
            </ul>
        </nav>



    </div>
</template>
  
<script>
import BlankG from '@/components/BlankBG.vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from "@/router";
export default {
    components: {
        BlankG,
    },
    data() {
        return {
            screenWidth: 1024,
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth
    },
    setup() {
        const store = useStore();

        const articleList = ref([])
        // const PAGE = 6;
        let currentPage = 1;
        let totalPage = 1;
        let showPage = ref([]);
        const byCondition = ref("");
        const byLabel = ref("");
        const hasTittle = ref("")

        const search_article_byTittle = () => {
            byCondition.value = true;
            byLabel.value = false

            pull_page(1);
        }

        const labelName = ref("")
        const search_article_byLabel = (label) => {
            byLabel.value = true
            byCondition.value = false
            labelName.value = label
            pull_page(1);

        }


        const draft_article = (articleId) => {
            $.ajax({

                url: store.state.BaseUrl + "/api/article/draft/" + articleId,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {
                        alert("加入草稿箱成功")
                        pull_page(1)
                    } else {
                        alert("加入草稿箱失败")
                    }

                    // articleList.value = resp.content.articleList;
                    // console.log(resp);
                    // totalPage = resp.content.totalPage;
                    // update_pages();
                    // console.log("拉取文章列表成功")


                },
                error() {
                    alert("加入草稿箱失败")
                }

            })

            // router.push({
            //     path: "/article-manage",
            // })


        }

        const show_article = (articleId) => {
            router.push({
                path: "/article",
                query: {
                    authorId: store.state.user.id,
                    articleId: articleId
                }
            })
        }

        const modify_article = (articleId) => {
            router.push({
                path: "/article-modify",
                query: {
                    // authorId: store.state.user.id,
                    articleId: articleId
                }
            })
        }

        const confirm_delete_article = (articleId) => {
            let flag;
            flag = confirm("是否确认永久删除，无法恢复")
            if (flag == true) {
                delete_article(articleId);
            }
        }

        const delete_article = (articleId) => {
            $.ajax({
                //url格式：article/list/页码/用户id/是否需要草稿/
                url: store.state.BaseUrl + "/api/article/delete/" + articleId,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {
                        alert("删除成功")
                        pull_page(1)
                    } else {
                        alert("删除失败")
                    }

                    // articleList.value = resp.content.articleList;
                    // console.log(resp);
                    // totalPage = resp.content.totalPage;
                    // update_pages();
                    // console.log("拉取文章列表成功")


                },
                error() {
                    alert("删除失败")
                },


            })
            // router.push({
            //     path: "/article-manage",
            // })
        }


        const update_pages = () => {
            // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
            let max_pages = totalPage;
            console.log("最大页数" + max_pages)

            let new_page = [];
            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }

            showPage.value = new_page;

        }

        const click_page = (page) => {
            if (page === -1) {
                page = currentPage + 1;

            } else if (page === -2) {
                page = currentPage - 1;
            }
            let max_pages = totalPage;
            if (page >= 1 && page <= max_pages) {
                console.log("获取页面：" + page)
                pull_page(page);
            }
        }


        const totalCount = ref("");
        const pull_page = (page) => {
            currentPage = page;

            if (byLabel.value) {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/common/list/byLabel/" + labelName.value + "/false/" + store.state.user.id + "/" + currentPage,
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },

                    success(resp) {

                        if (resp.code == 1) {
                            articleList.value = resp.content.articleList;
                            // console.log(resp.content.articleList);
                            totalPage = resp.content.totalPage;
                            totalCount.value = resp.content.totalCount;
                            update_pages();
                            console.log("拉取 " + labelName.value + " 文章列表成功")
                            // console.log("拉取文章列表成功")
                        }



                    },

                })
            }
            else if (byCondition.value) {
                $.ajax({
                    //url格式：article/list/页码/用户id/是否需要草稿/
                    url: store.state.BaseUrl + "/api/article/list/condition",
                    type: "post",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    data: {
                        hasTittle: hasTittle.value,
                        hasAuthorId: store.state.user.id,
                        pageNum: page

                    },

                    success(resp) {

                        articleList.value = resp.content.articleList;
                        console.log(resp);
                        totalPage = resp.content.totalPage;
                        totalCount.value = resp.content.totalCount;

                        update_pages();
                        console.log("拉取 " + hasTittle.value + " 文章列表成功")


                    },

                })
            } else {
                $.ajax({
                    //url格式：article/list/页码/用户id/是否需要草稿/
                    url: store.state.BaseUrl + "/api/article/list/" + page + "/" + store.state.user.id + "/false",
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },

                    success(resp) {

                        articleList.value = resp.content.articleList;
                        console.log(resp);
                        totalPage = resp.content.totalPage;
                        totalCount.value = resp.content.totalCount;

                        update_pages();
                        console.log("拉取文章列表成功")


                    },

                })
            }





        }

        const timestampToTime = (timestamp) => {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }

        pull_page(currentPage)
        return {
            articleList,
            click_page,
            showPage,
            show_article,
            delete_article,
            modify_article,
            draft_article,
            confirm_delete_article,
            search_article_byTittle,
            hasTittle,
            timestampToTime,
            search_article_byLabel,
            byCondition,
            byLabel,
            labelName,
            totalCount
        }





    }



};

</script>

<style scoped>
.article-list-padding {
    height: 50vh;
    width: 55vw;
    position: fixed;
    overflow-y: scroll;
}
</style>