<template>
    <BlankG v-if="screenWidth >= 1000">
        <div class="row" style="padding-top: 2vh;">
            <div class="col-3">
                <div class="set-article-padding">
                    <div class="mb-3">
                        <label for="titleControlInput" class="form-label">标题：</label>
                        <input type="text" class="form-control" id="titleControlInput" v-model="tittle"
                            placeholder="标题不能小于2字符">
                    </div>
                    <div class="mb-3 ">
                        <label for="formFile" class=" col-form-label">文章封面（可无）：</label>
                        <input class="form-control" type="file"
                            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg" placeholder="选择文件"
                            id="articleBgFile">

                    </div>
                    <div class="mb-3">
                        <label for="FormControlTextarea" class="form-label">摘要：</label>
                        <textarea class="form-control" id="FormControlTextarea" rows="3" v-model="brief"
                            placeholder="写文章简介"></textarea>
                    </div>

                    <div class="mb-3">
                        <form>
                            <label class="form-check-label" for="exampleCheck1">是否置顶文章：</label>
                            <input type="checkbox" class="form-check-input" v-model="isTop" id="exampleCheck1">
                        </form>
                    </div>


                    <div class="mb-3">
                        <div>
                            <input type="text" id="examplelabel" style="width: 70%; float: left;" v-model="newLabel">
                            <button @click="addLabel" class="btn btn-sm btn-info" style="width: 30%;">添加标签</button>

                            <!-- <div v-if="myLabels != null"> -->
                            <select class="form-select form-select-sm" v-if="myLabels != null" size="2"
                                style="width: 100%; ">
                                <!-- <option selected>选择已有标签：</option> -->
                                <option v-for="label in   myLabels  " :key="label.id"
                                    @click="setNewLabel(label.labelName)">
                                    {{
        label.labelName }}
                                </option>
                            </select>
                            <!-- <button @click="setNewLabel(label.split('*')[0])" class="btn btn-sm btn-info"
                            style="width: 30%;">添加标签</button> -->


                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="mb-3">

                        <div>
                            <label class="form-check-label" for="exampleCheck2">我的标签：</label>

                            <span v-for="   label   in   labelList  " :key="label.id">
                                <kbd @click="deleteLabel(label)">{{ label }}</kbd>&nbsp;
                            </span>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div v-if="isLoading" class="spinner-border text-primary col-sm-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <button v-else class="btn btn-bg btn-info" style="width: 100%;"
                            @click="upload_article(tittle, brief, text, isTop, labelList)">发布文章</button>

                    </div>
                    <div class="mb-3">

                    </div>
                </div>
            </div>
            <div class="col-9">

                <div class="write-article-padding">
                    <label class="form-label">文章编写：</label>
                    <v-md-editor v-model="text" height="70vh" mode="editable" placeholder="在这里编写您的文章"
                        left-toolbar="undo redo clear | emoji | h bold italic strikethrough quote | ul ol table hr | link image code "></v-md-editor>

                </div>


                <!-- <button class="btn btn-sm btn-warning" @click="upload_article(tittle, brief, text, labelList)">存为草稿</button> -->
            </div>

        </div>
    </BlankG>

    <div v-else style="padding: 1vw;">
        <div class="mb-3">
            <label for="titleControlInput" class="form-label">标题：</label>
            <input type="text" class="form-control" id="titleControlInput" v-model="tittle" placeholder="标题不能小于2字符">
        </div>
        <div class="write-article-padding">
            <label class="form-label">文章编写：</label>
            <v-md-editor v-model="text" height="60vh" mode="edit" placeholder="在这里编写您的文章"
                left-toolbar="undo redo clear | emoji | h bold italic strikethrough quote | ul ol table hr | link image code "></v-md-editor>

        </div>

        <div class="set-article-padding">

            <div class="mb-3 ">
                <label for="formFile" class=" col-form-label">文章封面（可无）：</label>
                <input class="form-control" type="file" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                    placeholder="选择文件" id="articleBgFile">

            </div>
            <div class="mb-3">
                <label for="FormControlTextarea" class="form-label">摘要：</label>
                <textarea class="form-control" id="FormControlTextarea" rows="3" v-model="brief"
                    placeholder="写文章简介"></textarea>
            </div>

            <div class="mb-3">
                <form>
                    <label class="form-check-label" for="exampleCheck1">是否置顶文章：</label>
                    <input type="checkbox" class="form-check-input" v-model="isTop" id="exampleCheck1">
                </form>
            </div>


            <div class="mb-3">
                <div>
                    <input type="text" id="examplelabel" style="width: 60%; float: left;" v-model="newLabel">
                    <button @click="addLabel" class="btn btn-sm btn-info" style="width: 40%;">添加标签</button>

                    <!-- <div v-if="myLabels != null"> -->
                    <select class="form-select form-select-sm" v-if="myLabels != null" size="2" style="width: 100%; ">
                        <!-- <option selected>选择已有标签：</option> -->
                        <option v-for="label in   myLabels  " :key="label.id" @click="setNewLabel(label.labelName)">
                            {{
        label.labelName }}
                        </option>
                    </select>
                    <!-- <button @click="setNewLabel(label.split('*')[0])" class="btn btn-sm btn-info"
                            style="width: 30%;">添加标签</button> -->


                    <!-- </div> -->
                </div>
            </div>
            <div class="mb-3">

                <div>
                    <label class="form-check-label" for="exampleCheck2">我的标签：</label>

                    <span v-for="   label   in   labelList  " :key="label.id">
                        <kbd @click="deleteLabel(label)">{{ label }}</kbd>&nbsp;
                    </span>
                </div>
            </div>

            <div class="mb-3">
                <div v-if="isLoading" class="spinner-border text-primary col-sm-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <button v-else class="btn btn-bg btn-info" style="width: 100%;"
                    @click="upload_article(tittle, brief, text, isTop, labelList)">发布文章</button>

            </div>
            <div class="mb-3">

            </div>
            <br>
            <br>
        </div>




        <!-- <button class="btn btn-sm btn-warning" @click="upload_article(tittle, brief, text, labelList)">存为草稿</button> -->



    </div>
</template>

<script>
import BlankG from '@/components/BlankBG.vue';
import { useStore } from 'vuex';
import $ from "jquery"
import router from '@/router';

import { ref } from 'vue';

export default {
    components: {
        BlankG,
    },
    data() {
        return {
            tittle: "",
            text: '',
            brief: "",
            labelSet: new Set(),
            labelList: new Array(),
            newLabel: "",
            isTop: false,
            screenWidth: 1024,



        };
    },

    mounted() {
        this.screenWidth = window.innerWidth

    },
    methods: {


        setNewLabel(label) {
            // console.log("选择：" + label)
            this.newLabel = label
        },


        addLabel() {
            if (this.newLabel == null || this.newLabel.length < 2 || this.newLabel.length > 15) return;
            if (this.labelSet.size > 5) {
                alert("标签不能超过5个")
                return
            }
            this.labelSet.add(this.newLabel)
            this.labelList = []
            this.labelSet.forEach((element) => {
                this.labelList.push(element)
            });
            this.newLabel = "";

            // console.log(this.labelList);
        },
        deleteLabel(name) {

            this.labelSet.delete(name)
            this.labelList = []
            this.labelSet.forEach((element) => {
                this.labelList.push(element)
            });
        }



    },

    setup() {
        const store = useStore();
        // let newLabel = ref("");
        const isLoading = ref("")
        isLoading.value = false;


        const drawingBedToken = ref("")
        const articleBgUrl = ref("")


        const get_drawing_bed_token = () => {
            $.ajax({

                url: store.state.BaseUrl + "/api/user/image/token/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                },
                success(resp) {

                    if (resp.code == 1) {
                        drawingBedToken.value = resp.content.token
                    }
                }

            })
        }
        get_drawing_bed_token()


        const update_articleBg = () => {
            const file = document.getElementById("articleBgFile");

            if (file == null) {
                return;
            }
            const fileOb = file.files[0];


            if (fileOb == null) {

                return
            }
            if (fileOb.length > 5 * 1024 * 1024) {

                alert("文章封面文件过大")
                return
            }

            const params = new FormData();
            params.append('image', fileOb)
            params.append("token", drawingBedToken.value)
            upload_image(params)
        }

        const upload_image = (params) => {
            $.ajax({
                url: "https://img.alowlife.com/api/index.php",
                type: "post",
                async: true,
                headers: {
                },
                data: params,
                mimeType: 'multipart/form-data',
                crossDomain: true,
                processData: false,
                contentType: false,
                success(resp) {
                    console.log(resp)
                    resp = $.parseJSON(resp)
                    if (resp.code == 200) {
                        articleBgUrl.value = resp.url
                        console.log(articleBgUrl.value)
                        // upload_image_url()
                    } else {
                        alert("文章封面上传失败")
                    }

                },
                error() {
                    alert("文章封面上传失败")

                }

            })
        }
        // const upload_image_url = () => {
        //     $.ajax({
        //         url: store.state.BaseUrl + "/api/article/",
        //         type: "put",
        //         headers: {
        //             Authorization: "Bearer " + store.state.user.token
        //         },
        //         data: {
        //             "bgUrl": articleBgUrl.value
        //         },
        //         success(resp) {
        //             console.log(resp)
        //         },
        //     })
        // }

        const upload_article = (tittle, brief, text, isTop, labels) => {
            isLoading.value = true;

            // console.log(tittle, brief, isTop, labels)
            // const request = {
            //     tittle,
            //     brief,
            //     text
            // }
            if (tittle == null || tittle.length <= 2) {
                isLoading.value = false;
                alert("标题不能少于两个字符")
                return
            }
            if (text == null || text.length <= 20) {
                isLoading.value = false;
                alert("文章不能少于20个字符")
                return
            }

            $.ajaxSetup({
                traditional: true
            });

            get_drawing_bed_token()
            update_articleBg()

            setTimeout(() => {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/",
                    type: "post",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token
                    },
                    data: {
                        "tittle": tittle,
                        "brief": brief,
                        "content": text,
                        "isTop": isTop,
                        "bgUrl": articleBgUrl.value,
                        labels
                    },
                    success(resp) {
                        isLoading.value = false;
                        if (resp.code == 1) {
                            tittle = "",
                                brief = "",
                                text = "",
                                isTop = false,
                                labels = "",
                                alert(resp.content.article)
                            router.push({
                                path: "/article-write",
                            })

                        } else {
                            alert(resp.content.article)
                        }
                    },
                    error() {
                        isLoading.value = false;
                        console.log("文章发布失败")
                        alert("文章发布失败")

                    }
                })

            }, 4000);


        }


        const myLabels = ref([])
        const getMyLabels = () => {
            // if (myLabels.value.length >= 1) return;

            $.ajax({
                url: store.state.BaseUrl + "/api/label/common/list/" + store.state.user.id,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {
                        myLabels.value = resp.content
                        // console.log(myLabels)
                    } else {
                        console.log("拉取个人标签失败")
                    }
                },
            })
        }
        getMyLabels()





        return {
            isLoading,
            upload_article,
            myLabels,
            // newLabel
        }
    }


};

</script>

<style scoped>
.set-article-padding {
    /* background-color: aliceblue; */
    /* height: 00px; */
    padding-top: 3vh;
}

.write-article-padding {}
</style>