<template>
    <!-- <nav class="navbar navbar-expand-lg " style="background-color: #e3f2fd;"> -->
    <nav  class="navbar navbar-expand-lg navbar-fixed-top navbar-dark"
        style="background-color:rgba(0, 0, 0, 0.5);  font-size: larger;  font-family: '微软雅黑'; ">
        <div class="container ">
            <!-- <router-link class="navbar-brand" to="/"> -->
            <!-- <img src="ico/bulb_light.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top"> -->
            <router-link class="navbar-brand tttext" to="/">
                <!-- <text style="text-decoration: line-through;">未来、过去</text>、现在! -->
                好好加油！{{ mouseX }}%

                <div class="progress-container" >
  <div class="progress-bar" :style="'width: '+mouseX+'%;'"></div>
</div>

                
                <!-- <div class="progress">
  <div class="progress-bar" role="progressbar" :style="'width: '+mouseX+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div> -->
                 <!-- {{ mouseY }} -->
            </router-link>


            <!-- </router-link> &nbsp; -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <!-- <form class="d-flex" v-if="$store.state.user.is_login">

                        <input v-model="hasTittle" class="form-control me-2" type="search" placeholder="搜索文章"
                            aria-label="Search">
                        <button data-bs-toggle="modal" data-bs-target="#searchArticleListModal"
                            class="btn btn-outline-success" type="button" @click="pull_page(1)">search</button>
                    </form> -->
                </ul>
                <ul class="navbar-nav">

                </ul>

                <ul v-if="$store.state.user.is_login" class="navbar-nav">
                    <li class="nav-item">
                        <!-- <a @click="userLogout" class="nav-link" href="">{{ $store.state.user.username }}</a> -->
                        <!-- <span> &nbsp;<b>{{ $store.state.user.username }}</b></span> -->
                        <!-- <router-link class="nav-link" to="/">首页</router-link> -->


                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link tttext" :to="{ name: 'studyPlan' }">计划</router-link>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link dropdown-toggle tttext" data-bs-toggle="collapse" href="#timerView"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">秒表 </a>
                    </li>


                    <li class="nav-item dropdown" v-if="myLabels.length >= 1 && $store.state.user.is_login"
                        style="color: white;">
                        <!-- <li class="nav-item dropdown" v-if="$store.state.user.is_login"> -->

                        <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <!-- {{ $store.state.user.username }} -->
                            标签
                        </router-link>
                        <ul class="dropdown-menu">
                            <!-- <div v-for="label in myLabels" :key="label.id"> -->
                            <!-- <li>
                                <hr class="dropdown-divider">
                            </li> -->
                            <li><router-link v-for="label in myLabels" :key="label.id" class="dropdown-item tttext"
                                    @click="show_label_article(label.labelName, 1)" to="">{{ label.labelName
                                    }}</router-link>
                            </li>
                            <!-- </div> -->


                        </ul>
                    </li>


                    <li class="nav-item tttext" v-else @click="getMyLabels">
                        <router-link class="nav-link" to="" @click="alert_msg('您还没有创建标签呢，可以在创建文章时创建标签呢')">
                            标签</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            写作
                        </router-link>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'article_write' }">编写文章</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'article_manage' }">文章管理</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>

                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'article_draft' }">草稿箱</router-link></li>
                        </ul>
                    </li>
                    <li class="nav-item ">
                        <!-- <router-link class="nav-link" :to="{ name: 'music' }" target="_blank">
                            音乐</router-link> -->
                        <a class="nav-link dropdown-toggle tttext" data-bs-toggle="collapse" href="#musicView"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">音乐 </a>


                    </li>
                    <!-- <li class="nav-item ">
                        <a class="nav-link dropdown-toggle tttext" data-bs-toggle="collapse" href="#timerView"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">秒表 </a>
                    </li> -->
                    <li class="nav-item dropdown">
                        <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ $store.state.user.username }}
                        </router-link>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'user_profile' }">个人资料</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'modify_profile' }">修改资料</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>

                            <li>
                                <a @click="confirm_user_logout" class="dropdown-item tttext" href="">注销</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            其他
                        </router-link>
                        <ul class="dropdown-menu">
                            <!-- <li><router-link class="dropdown-item" :to="{ name: 'else' }">关于</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li> -->
                            <li><router-link class="dropdown-item tttext" :to="{ name: 'myBg' }">我</router-link></li>

                        </ul>
                    </li>

                    <li class="nav-item dropdown" v-if="$store.state.user.is_admin" style="color: white;">
                        <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            管理
                        </router-link>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'admin_user' }">用户管理</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'admin_article' }">文章管理</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>

                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'admin_comment' }">评论管理</router-link></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>

                            <li><router-link class="dropdown-item tttext"
                                    :to="{ name: 'admin_label' }">标签管理</router-link></li>
                        </ul>
                    </li>


                </ul>
                <ul v-else class="navbar-nav">

                    <!-- <li class="nav-item">
                        <router-link class="nav-link tttext" to="/">首页</router-link>
                    </li> -->

                    <li class="nav-item">
                        <router-link class="nav-link tttext" :to="{ name: 'studyPlan' }">计划</router-link>
                    </li>

                    <li class="nav-item ">
                        <a class="nav-link dropdown-toggle tttext" data-bs-toggle="collapse" href="#timerView"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">秒表 </a>
                    </li>

                    <!-- <li class="nav-item">
                        <router-link class="nav-link tttext" to=""
                            @click="show_label_article('golang', 1)">Golang</router-link>
                    </li> -->

                    <li class="nav-item dropdown" v-if="myLabels.length >= 1">

                        <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">

                            技术
                        </router-link>
                        <ul class="dropdown-menu tttext"
                            style="background-color:rgba(255, 255, 255, 0.9); max-height: 200px; overflow-y: scroll;">
                            <li><router-link style="font-weight: bolder;" v-for="label in myLabels" :key="label.id"
                                    class="dropdown-item tttext" @click="show_label_article(label.labelName, 1)" to="">
                                    {{ label.labelName }} </router-link>
                            </li>
                        </ul>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="nav-link tttext" to="" @click="show_label_article('日常', 1)">日常</router-link>
                    </li> -->



                    <li class="nav-item ">
                        <a class="nav-link dropdown-toggle tttext" data-bs-toggle="collapse" href="#musicView"
                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">音乐 </a>
                    </li>




                    <!-- <li class="nav-item">
                        <router-link class="nav-link tttext" :to="{ name: 'myBg' }">作怪</router-link>
                    </li> -->

                    <li class="nav-item dropdown">
                        <!-- <router-link class="nav-link" :to="{ name: 'music' }" target="_blank">
                            音乐</router-link> -->

                            <router-link class="nav-link dropdown-toggle tttext" to="" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">

                            作怪
                        </router-link>
                        
                        
                            <ul class="dropdown-menu tttext"
                            style="background-color:rgba(255, 255, 255, 0.9); max-height: 200px; overflow-y: scroll;">
                            <li>

                                <router-link class="dropdown-item tttext" to="" @click="show_label_article('日常', 1)">日常</router-link>
                            </li>
                            <li>
                            <a href="https://zuoguai.github.io/AboutMe" class="dropdown-item tttext" target="_blank">AboutMe</a>

                            </li>

                        </ul>


                    </li>


                    <!-- <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'login' }">登录</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'register' }">注册</router-link>
                    </li> -->

                </ul>

            </div>
        </div>
    </nav>
    <div style="" class="collapse multi-collapse" id="musicView">
        <div class="">
            <MusicView />
        </div>
    </div>
    <div style="" class="collapse multi-collapse" id="timerView">
        <div class="">
            <TimerView />
        </div>
    </div>

    <!-- <div id="searchArticleListModal" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        ...
    </div> -->

    <div class="modal fade" id="searchArticleListModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">文章</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="list-group">
                        <div v-for="article in articleList" :key="article.id">
                            <a href="#" class="list-group-item list-group-item-action ">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ article.tittle }}</h5>
                                    <small>{{ new Date(article.createTime).toLocaleDateString() }}</small>
                                </div>
                                <p class="mb-1">简介：{{ article.brief }}</p>

                                <span>

                                    <!-- <button type="button" @click="confirm_delete_article(article.articleId)"
                                        class="btn btn-sm btn-danger" style="float: right; margin-left: 3px;">删除</button>
                                    <button type="button" @click="modify_article(article.articleId)"
                                        class="btn btn-sm btn-warning" style="float: right; margin-left: 3px;">修改</button> -->
                                    <!-- <button type="button" @click="draft_article(article.articleId)"
                                    class="btn btn-sm btn-success" style="float: right; margin-left: 3px;">草稿</button> -->
                                    <button type="button" data-bs-dismiss="modal"
                                        @click="show_article(article.articleId)" class="btn btn-sm btn-info"
                                        style="float: right; margin-left: 3px;">查看</button>

                                </span>


                                <small>文章热度：{{ article.viewCount }}</small>

                            </a>
                        </div>

                    </div>
                    <br>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination  pagination-sm " style="float:right">
                            <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link"
                                    href="#">上一页</a>
                            </li>
                            <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                                v-for=" page in showPage" :key="page.number">
                                <a class="page-link" href="#">{{ page.number }}</a>
                            </li>

                            <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link"
                                    href="#">下一页</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useStore } from 'vuex';
import router from '@/router';
import { ref } from 'vue';
import $ from "jquery"
import MusicView from '@/views/addition/MusicView.vue';
import TimerView from '@/views/addition/TimerView.vue';
// import { inject } from 'vue';
export default {
    name: "NavBar",
    components: {
        MusicView: MusicView,
        TimerView: TimerView,
    },
    props: {
    mouseX: String,
    mouseY: String,
  },
    data() {
        return {
            // myLabels: []
        }
    },
    mounted() {





    },
    methods: {

        alert_msg(msg) {
            alert(msg)
        },


    },

    setup() {
        const store = useStore()

        const confirm_user_logout = () => {
            let flag;
            flag = confirm("是否确认注销，将退出登录")
            if (flag == true) {
                userLogout()
            } else {
                return
            }
        }

        const userLogout = () => {

            store.dispatch("logout", {
                success() {
                    router.push({ name: "login" })
                },
                error() {

                }
            }
            )


        }

        const articleList = ref([]);
        let currentPage = 1;
        let totalPage = 1;
        let showPage = ref([]);

        const hasTittle = ref("")



        // 检测登录
        if (!store.state.user.is_login) {
            const my_jwt_token = localStorage.getItem("my_jwt_token");
            if (my_jwt_token != null && my_jwt_token != "" && !store.state.user.is_login) {

                store.commit("updateToken", my_jwt_token);
                store.dispatch("getInfo", {
                    success() {
                        // router.push({ name: "home" })
                        getMyLabels();
                    },
                    error() {
                        console.log("拉取用户信息失败")
                    }
                })
            }
        }

        // const reloadPage = inject("reload")
        const show_label_article = (labelName, myArticle) => {

            let userId = store.state.user.id
            // alert(labelName)
            if (store.state.user.id == 0) {
                userId = 1
            }

            // console.log(labelName)
            router.push({
                path: "/label-article-list",
                query: {
                    authorId: userId,
                    myArticle: myArticle,
                    // articleId: articleId
                    labelName: labelName,
                    // needReload: needReload
                }
            })
            // reloadPage()
            // reloadPage()
            // if (needReload) 
        }

        const myLabels = ref([])

        const getMyLabels = () => {
            // if (myLabels.value.length >= 1) return;
            let userId = store.state.user.id
            if (userId == 0) {
                userId = 1
            }
            // console.log("userId : "+userId)

            $.ajax({
                url: store.state.BaseUrl + "/api/label/common/list/" + userId,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {

                        myLabels.value = resp.content.filter(item => 
                        // item.labelName != "golang"&& 
                        item.labelName != "日常"
                        )


                        // console.log(myLabels)
                    } else {
                        console.log("拉取个人标签失败")
                    }
                },
            })
        }
        // if (store.state.user.is_login)
        setTimeout(getMyLabels(), 2000);




        const show_article = (articleId) => {
            // location.reload()
            hasTittle.value = ""
            // router.push({ path: "/404" })
            router.push({
                path: "/article",
                query: {
                    authorId: store.state.user.id,
                    articleId: articleId
                }
            })
            // reloadPage()

            // router.go(0)
        }

        const update_pages = () => {
            // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
            let max_pages = totalPage;
            console.log("最大页数" + max_pages)

            let new_page = [];
            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }

            showPage.value = new_page;

        }

        const click_page = (page) => {
            if (page === -1) {
                page = currentPage + 1;

            } else if (page === -2) {
                page = currentPage - 1;
            }
            let max_pages = totalPage;
            if (page >= 1 && page <= max_pages) {
                console.log("获取页面：" + page)
                pull_page(page);
            }
        }

        const pull_page = (page) => {
            if (hasTittle.value == null || hasTittle.value == "") return;
            currentPage = page;

            $.ajax({
                //url格式：article/list/页码/用户id/是否需要草稿/
                url: store.state.BaseUrl + "/api/article/list/condition",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                    hasTittle: hasTittle.value,
                    pageNum: page


                },
                success(resp) {
                    articleList.value = resp.content.articleList;
                    console.log(resp);
                    totalPage = resp.content.totalPage;
                    update_pages();
                    console.log("拉取 " + hasTittle.value + " 文章列表成功")
                },
            })


        }

        // pull_page(currentPage)
        return {

            show_article,
            pull_page,
            confirm_user_logout,
            articleList,
            click_page,
            showPage,
            hasTittle,
            getMyLabels,
            myLabels,
            show_label_article
        }
    }
}


</script>


<style scoped>
/* nav a.router-link-exact-active {
  color: #42b983;
} */
 
.progress-container {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
}
 
.progress-bar {
  height: 2px;
  background-color: #ffffff;
  transition: width 1s ease-in-out;
  border-radius: 4px;
  width: 0; /* 初始宽度为0 */
}
nav:hover {
    background-color: rgb(231, 31, 31);
}

li {
    color: white;
}

.tttext {
    border-bottom: 2px solid #000000;
}

.tttext:hover {
    /* border-bottom: 3px solid #000000; */
    /* border: 5px solid rgb(231,231,231); */
    /* border-radius: 3%; */
    opacity: 1;
    /* border: 1px solid rgb(255, 255, 255); */
    border-bottom: 2px solid #ffffff;

    font-weight: 900;
    color: white;



}


ul::-webkit-scrollbar {
    width: 8px;
    height: 1px;
}

ul::-webkit-scrollbar-thumb {
    background: grey;
}

ul::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px white;
}
</style>