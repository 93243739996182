<template>
    <div class="container " style="margin-top: 2%;">
    <!-- <div class="card opacity-75">
        <div class="card-body"> -->
            
                <slot></slot>
            <!-- </div>
        </div> -->
      </div>

</template>

<script>
export default{
    name: "SpaceWar",
    
}

</script>

<style>


</style>