<template>
    <div v-if="screenWidth >= 1000">
        <BlankBG style="opacity: 1; ">

            <nav class="navbar pre-text mid-text" style="border-radius: 0%; ">
                <div class="container-fluid ">
                    <a class="nav-link"  v-if="hasTittle == null || hasTittle == ''" >最新：
                        <text style="color: black;  /* 这里是超出几行省略 */ overflow: hidden;" v-if="hitokoto != null && hitokoto.data!= null && hitokoto.data.content != null && hitokoto != ''">{{ hitokoto.data.content +'  ——  '+ hitokoto.data.author}}</text>
                        <text style="color: black;" v-else>千金散尽还复来</text>
                    </a>
                    <a class="nav-link " v-else>最新：“{{hasTittle}}”
                    </a>
                    <div class="d-flex"  v-if="$store.state.user.is_login||true">
                        <input style="border-radius: 0%;" @keyup.enter="pull_page(1)" v-model="hasTittle" class="form-control me-2" type="search"
                            :placeholder="'发现' + totalCount + '篇文章'" aria-label="Search">
                        <button style="border-radius: 0%;" class="btn btn-outline-success" type="button" @click="pull_page(1)">search</button>
                    </div>
                    <!-- <div class="d-flex" v-else>
                        <input @keyup.enter="xxx" v-model="xxx" class="form-control me-2" type="search"
                        :placeholder="'发现' + totalCount + '篇文章'" aria-label="Search">
                        <button class="btn btn-outline-success" type="button" @click="xxx">search</button>
                    </div> -->
                </div>
            </nav>
            <br>


            <div class="row">
                <div class="col-9">
                    <div class="list-group">
                        <div v-for="  articleItem   in   articleList " :key="articleItem.id" style="">
                            <div class="card mb-3 mid-text pre-text" style="max-width: 100%; height: 150px;border: none;  border-radius: 0%;
/* border: 1px solid rgb(216, 211, 192) */
                             ">
                                <div class="row g-0" style="">
                                    <div class="col-md-4 " style="height: 150px; overflow: hidden;  ">

                                        <img v-if="articleItem.article.bgUrl == null || articleItem.article.bgUrl == ''"
                                            src="bulb.png" style="height: auto; width: 80%; " class="opacity-for-img"
                                            onerror="this.src='bulb.png';">
                                        <img v-else :src="articleItem.article.bgUrl"
                                            style=" height: auto; width: 100%; overflow:hidden ; "
                                            class="opacity-for-img" onerror="this.src='bulb.png';">
                                    </div>
                                    <div class="col-md-8 ">
                                        <div class="card-body " style="overflow: hidden; border-radius: 0%; ">
                                            <h5 class="card-title">
                                                <router-link class="nav-link" style="text-decoration: none;"
                                                    :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }">
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <p class="mb-1"
                                                            style="font-weight:bolder; text-overflow:ellipsis; overflow: hidden; white-space: nowrap; font-size: larger;">
                                                            {{
        articleItem.article.tittle
    }}</p>
                                                        <p class="mb-1" style="float: right; font-size: small;">发布时间： {{
            timestampToTime(Date.parse(new
                Date(articleItem.article.createTime).toString())) }}</p>
                                                    </div>



                                                </router-link>
                                            </h5>
                                            <p class="card-text"
                                                style="font-size: small; word-break: break-all; text-overflow: ellipsis;
                                                    display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2; /* 这里是超出几行省略 */ overflow: hidden;">
                                                简介：
                                                {{ articleItem.article.brief }}

                                            </p>

                                            <!-- <div class="d-flex w-100 justify-content-between" style="float:;"> -->

                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="mb-1 ">
                                                        <!-- <small>&nbsp;</small> -->
                                                        <p style="font-size: small;">作者：
                                                            <router-link style="text-decoration: none;  color: black;"
                                                                :to="{ name: 'friend', query: { friendId: articleItem.article.authorId } }">
                                                                <kbd>{{ articleItem.author }} </kbd>

                                                            </router-link>
                                                            热度：<kbd v-if="articleItem.article.viewCount < 100">
                                                                {{ articleItem.article.viewCount }}</kbd>
                                                            <kbd v-else>{{ 99 + "+" }}</kbd>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <!-- text-overflow:ellipsis; float:right; overflow: hidden; white-space: nowrap; -->
                                                    <div
                                                        style="font-size: small; word-break: break-all; text-overflow: '...';
                                                    display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;">
                                                        <span class="mb-1 " v-for="  label   in articleItem.labelList"
                                                            :key="label.id" style="overflow: hidden; ">

                                                            <mark @click="show_label_article(label, 0)">{{ label
                                                                }}</mark> &nbsp;
                                                        </span>
                                                    </div>

                                                </div>

                                            </div>



                                            </div>

                                            <!-- </div> -->

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <!-- <div class="list-group">
                        <div v-for="  articleItem   in   articleList  " :key="articleItem.id">
                            <div style="height: 120px; background-color: white;">

                                <div class="" style="border-radius: 1%; border: none;">

                                    <div style=" overflow: hidden; float: left;">

                                        <img v-if="articleItem.article.bgUrl == null"
                                            src="https://img.alowlife.com/i/2023/11/06/h1if2t-2.png"
                                            style="width: auto; height: 90px; float: left; overflow: hidden;">
                                        <img v-else :src="articleItem.article.bgUrl" onerror="this.src='bulb.png';"
                                            style="max-height: 120px; width: 100%; float: left; overflow: hidden;">
                                    </div>
                                    <div style="float: left;">&nbsp;</div>
                                    <div style="float: left;">
                                        <router-link style="color: black;  text-decoration: none;"
                                            :to="{ name: 'article', query: { articleId: articleItem.article.articleId, authorId: articleItem.article.authorId } }">
                                            <div class="d-flex w-100 justify-content-between">
                                                <p class="mb-1" style="font-weight: bolder;">{{
                                                    articleItem.article.tittle
                                                }}</p>

                                            </div>
                                            <p class="mb-1" style="float: right;">发布时间： {{ timestampToTime(Date.parse(new
                                                Date(articleItem.article.createTime).toString())) }}</p>
                                            <p class="mb-1" style="font-size: small;">简介：{{ articleItem.article.brief }}
                                            </p>

                                        </router-link>
                                        <small>作者： <router-link style="text-decoration: none; float: left; color: black;"
                                                :to="{ name: 'friend', query: { friendId: articleItem.article.authorId } }">
                                                <kbd>{{ articleItem.author }} </kbd>

                                            </router-link> &nbsp; 热度：<kbd>{{
                                                articleItem.article.viewCount }}</kbd>
                                        </small>
                                        <span v-for="  label   in   articleItem.labelList  " :key="label.id"
                                            style="float: right;">
                                            <p style="float: right; margin-left: 3px;">
                                                
                                                <mark @click="show_label_article(label, 0)">{{ label }}</mark>
                                            </p>
                                        </span>
                                    </div>
                                    
                                </div>

                            </div>
                            <br>
                            


                        </div>
                    </div> -->
                    <nav aria-label="Page navigation example">
                        <button class="btn  btn-outline-warning btn-sm" style="float:right; border-radius: 0%;" @click="gotoPageNum()">跳转</button>
                        <input type="number" class="form-control form-control-sm" style="width: 120px; float:right; border-radius: 0%;"
                            :placeholder="'共有' + parseInt(totalCount / 9 + 1) + '页'" v-model="toPageNum">
                        &nbsp;&nbsp;&nbsp;
                        <ul class="pagination  pagination-sm" style="float:right; border-radius: 0%;">
                            <li class="page-item" @click="click_page(-2)" ><a class="page-link" href="#">上一页</a></li>
                            <li @click="click_page(page.number)" :class="'page-item ' + page.is_active"
                                v-for="    page    in    showPage   " :key="page.number">
                                <a class="page-link" href="#">{{ page.number }}</a>
                            </li>
                            <li class="page-item" @click="click_page(-1)"><a class="page-link" href="#">下一页</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="col-3">
                    <div class="article-side-padding" style="opacity: 1; color:white;">
                        <div class="right-tag-padding">
                            <b style="color: black;  font-size: larger;">推荐:</b>
                            <br>
                            <span v-for=" label  in  hotLabelList " :key="label.id">
                                <router-link
                                    :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 0 } }"
                                    style="font-size: medium; text-decoration: none; color: white;">
                                    <kbd class="  right-text">
                                        {{ label.labelName }}
                                    </kbd>
                                </router-link>
                                    &nbsp;



                            </span>
                        </div>
                        <br>

                        <div class="right-article-padding">
                            <b style=" color:black; font-size: large;">最热:</b>
                            <!-- <hr class="hr-wavy"> -->
                            <div style="" v-for=" articleItem  in  hotArticleList " :key="articleItem.id">


                                <router-link class="right-text"
                                    :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }"
                                    style="color:white; ">
                                    <mark style="font-size: smaller; color:black;">
                                        {{ articleItem.article.tittle }}
                                    </mark>
                                    <span style="font-size: smaller;">
                                        <!-- <mark>热度：{{ articleItem.article.viewCount }} </mark> -->

                                    </span>
                                </router-link>
                                <br>
                                <!-- <hr class="hr-wavy"> -->

                            </div>

                        </div>
                    </div>
                </div>


            </div>




        </BlankBG>
    </div>
    <div v-else class="" style="opacity:1;">
        <!-- ==============================================================================手机版=========================== -->
        <nav  v-if="$store.state.user.is_login || true" class="navbar" style="border-radius: 0%; display: flex; background-color: rgba(0, 0, 0, 0);
    color: #ededed;
  align-items: center;justify-content: center; padding: 2vw;">
            <form class="d-flex" style="width: 100%;">
                <input @keyup.enter="pull_page(1)" v-model="hasTittle" class="form-control me-2" type="search"
                    :placeholder="'全站已有' + totalCount + '篇文章'" aria-label="Search">
                <button class="btn btn-outline-success" type="button" @click="pull_page(1)">search</button>
            </form>
        </nav>
        <!-- <br> -->

        <b style="padding: 2vw;">推荐:</b>
        <div class="" style="padding: 2vw;">
            <!-- <div class="card">
                    <div class="card-body"> -->
            <div style="background-color: rgba(255, 255, 255, 0.5)">
                <div style="padding: 2vw; max-height: 100px; overflow-y: auto;">
                    <span v-for=" label  in  hotLabelList " :key="label.id">
                        <router-link
                            :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 0 } }"
                            style="font-size: medium; text-decoration: none;">
                            <mark style="color:white; background-color: #5c5a5b; border-radius: 0%;">{{
        label.labelName }}
                            </mark></router-link>&nbsp;



                    </span>
                </div>
            </div>
            <!-- </div>
                </div> -->
        </div>
        <!-- <b style="padding: 2vw;">最新:</b> -->

        <div class="list-group" style="padding-left: 2vw; padding-right: 2vw;">
            <!-- <div class="card">
                <div class="card-body"> -->
            <div v-for="  articleItem   in   articleList  " :key="articleItem.id">
                <div style="padding-top: 1vw;"></div>
                <div style="background-color:  white; height: 120px; border:solid 0px rgb(255, 234, 151)">
                    <div class="row">
                        <div class="col-5">

                            <div style="height: 118px;  overflow: hidden;">

                                <img v-if="articleItem.article.bgUrl == null"
                                    src="https://img.alowlife.com/i/2023/11/06/h1if2t-2.png"
                                    style="width: auto; height: 90px; float: left; overflow: hidden;">
                                <img v-else :src="articleItem.article.bgUrl" onerror="this.src='bulb.png';"
                                    style="width: 100%; height: auto; float: left; overflow: hidden;">
                            </div>
                        </div>
                        <div class="col-7">
                            <div style="">
                                <router-link style="color: black; text-decoration: none;"
                                    :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }">


                                    <p class="mb-1"
                                        style="font-weight: bolder; font-size: large; overflow: hidden; white-space: nowrap;">
                                        {{ articleItem.article.tittle }}
                                    </p>
                                    <p class="mb-1" style="font-size: 10px;">
                                        {{ timestampToTime(Date.parse(new
        Date(articleItem.article.createTime).toString())) }}
                                    </p>
                                    <p class="mb-1" style="font-size: 10px; overflow: hidden; white-space: nowrap;">
                                        简介：{{ articleItem.article.brief }}
                                    </p>
                                </router-link>
                                <p
                                    style="font-size: 10px; white-space: nowrap; max-width: 100%; display: inline-block; overflow: hidden;">

                                    标签：
                                    <span class="mb-1 " v-for="  label   in articleItem.labelList" :key="label.id"
                                        style="overflow: hidden; ">

                                        <mark @click="show_label_article(label, 0)">{{ label
                                            }}</mark> &nbsp;
                                    </span>
                                    <br>
                                    作者： <router-link style="text-decoration: none; color: black;"
                                        :to="{ name: 'friend', query: { friendId: articleItem.article.authorId } }">
                                        <kbd>{{ articleItem.author }} </kbd>

                                    </router-link>
                                    <!-- 热度：{{ articleItem.article.viewCount }} -->


                                </p>
                                <!-- <p
                                            style="font-size: 10px; white-space: nowrap; max-width: 100%; display: inline-block; overflow: hidden;">

                                        </p> -->
                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div style="padding-top: 5px;">
                <nav aria-label="Page navigation example">

                    <ul class="pagination  pagination-sm " style="float:right">
                        <li class="page-item" @click="click_page(-2)"><a class="page-link" href="#">上一页</a></li>
                        <li @click="click_page(page.number)" :class="'page-item ' + page.is_active"
                            v-for="    page    in    showPage   " :key="page.number">
                            <a class="page-link" href="#">{{ page.number }}</a>
                        </li>
                        <li class="page-item" @click="click_page(-1)"><a class="page-link" href="#">下一页</a></li>

                    </ul>

                </nav>
            </div>
            <div style="padding-top: 0px;">
                <ul>
                    <li>
                        <button class="btn btn-primary " style="float:right" @click="gotoPageNum()">跳转</button>
                        <input type="number" class="form-control" style="width: 120px; float:right"
                            :placeholder="'共有' + parseInt(totalCount / 9 + 1) + '页'" v-model="toPageNum">
                    </li>
                </ul>
            </div>

            <!-- </div>


            </div> -->
        </div>





        <hr class="hr-wavy">
        <div>
            <b style="padding: 5px;">推荐:</b>
            <div class="" style="padding: 2vw;">
                <!-- <div class="card">
                    <div class="card-body"> -->
                <div style="background-color: rgba(255, 255, 255, 0.5)">
                    <div style="padding: 2vw; max-height: 100px; overflow-y: auto;">
                        <span v-for=" label  in  hotLabelList " :key="label.id">
                            <router-link
                                :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 0 } }"
                                style="font-size: medium; text-decoration: none;">
                                <mark style="color:white; background-color: #5c5a5b; border-radius: 0%;">{{
        label.labelName }}
                                </mark></router-link>&nbsp;



                        </span>
                    </div>
                </div>
                <!-- </div>
                </div> -->
            </div>
            <hr class="hr-wavy">

            <b style="padding: 5px;">最热:</b>

            <div style="padding: 2vw; ">
                <!-- <div class="card">
                    <div class="card-body"> -->
                <div style="background-color: rgba(255, 255, 255, 0.5)">
                    <div style=" padding: 1vw; max-height: 200px; overflow-y: auto;">
                        <div v-for=" articleItem  in  hotArticleList " :key="articleItem.id">


                            <router-link
                                :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }"
                                style="color:#5c5a5b; font-size: small; ">
                                {{ articleItem.article.tittle }}
                                <span style="font-size: smaller;">
                                    <!-- <mark>热度：{{ articleItem.article.viewCount }} </mark> -->

                                </span>
                            </router-link>

                        </div>
                    </div>
                </div>
                <!-- </div>
                </div> -->
            </div>
            <hr class="hr-wavy">
            <hr class="hr-wavy">

        </div>

    </div>
</template>

<script>

import BlankBG from "@/components/BlankBG.vue"
// import { useStore } from "vuex";
import store from "@/store";
import $ from "jquery"
// import ArticleBar from "@/components/ArticleBar.vue"
import { ref } from "vue";
import router from "@/router";

export default {
    // namespaced: true,
    name: "HomePage",
    components: {
        BlankBG,
        // ArticleBar,
    },
    data() {
        return {
            screenWidth: 1024
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth;
        console.log("屏幕宽度：" + this.screenWidth);
    },

    methods: {
        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
    },

    setup() {


        const hasTittle = ref("")
        // const store = useStore();
        const articleList = ref([]);
        let currentPage = 1;
        let totalPage = 1;
        let showPage = ref([]);

        const toPageNum = ref("");

        const gotoPageNum = () => {
            if (toPageNum.value <= 0 || toPageNum.value > totalPage) {
                toPageNum.value = 1
                // alert("页码超出范围")
                return
            }
            if (toPageNum.value == currentPage) {
                return
            }
            pull_page(toPageNum.value)

        }

        const update_pages = () => {
            // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
            let max_pages = totalPage;
            // console.log("最大页数" + max_pages)

            let new_page = [];
            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }

            showPage.value = new_page;

        }

        const click_page = page => {
            if (page === -1) {
                page = currentPage + 1;

            } else if (page === -2) {
                page = currentPage - 1;
            }
            let max_pages = totalPage;
            if (page >= 1 && page <= max_pages) {
                // console.log("获取页面：" + page)
                pull_page(page);
            }
        }

        const totalCount = ref("")
        totalCount.value = 1
        const pull_page = page => {
            currentPage = page;
            if (hasTittle.value == null || hasTittle.value == "") {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/common/list/" + currentPage,
                    type: "get",
                    // headers: {
                    //     Authorization: "Bearer " + store.state.user.token,
                    // },

                    success(resp) {

                        articleList.value = resp.content.articleList;
                        totalCount.value = resp.content.totalCount;
                        totalPage = resp.content.totalPage;
                        update_pages();
                        // console.log("拉取文章列表成功")
                        console.log(resp)


                    },

                })
            } else if(store.state.user.is_login){
                $.ajax({
                    //url格式：article/list/页码/用户id/是否需要草稿/
                    url: store.state.BaseUrl + "/api/article/list/condition",
                    type: "post",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    data: {
                        hasTittle: hasTittle.value,
                        hasAuthorId: 0,
                        pageNum: page
                    },
                    success(resp) {
                        if (resp.code == 1) {
                            articleList.value = resp.content.articleList;
                            // console.log(resp);
                            totalCount.value = resp.content.totalCount;

                            totalPage = resp.content.totalPage;
                            // hasTittle.value = ""
                            update_pages();
                            // console.log("拉取 " + hasTittle.value + " 文章列表成功")
                        } else {
                            alert("没有更多【" + hasTittle.value + "】的文章了")
                        }

                    },
                })
            }else {
                $.ajax({
                    //url格式：article/list/页码/用户id/是否需要草稿/
                    url: store.state.BaseUrl + "/api/article/common/list/condition",
                    type: "post",
                    data: {
                        hasTittle: hasTittle.value,
                        hasAuthorId: 0,
                        pageNum: page
                    },
                    success(resp) {
                        if (resp.code == 1) {
                            articleList.value = resp.content.articleList;
                            // console.log(resp);
                            totalCount.value = resp.content.totalCount;

                            totalPage = resp.content.totalPage;
                            // hasTittle.value = ""
                            update_pages();
                            // console.log("拉取 " + hasTittle.value + " 文章列表成功")
                        } else {
                            alert("没有更多【" + hasTittle.value + "】的文章了")
                        }

                    },
                })
            }
        }

        const timestampToTime = (timestamp) => {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }

        const show_label_article = (labelName, myArticle) => {
            router.push({
                path: "/label-article-list",
                query: {
                    authorId: store.state.user.id,
                    myArticle: myArticle,
                    labelName: labelName,
                }
            })
        }

        const hotLabelList = ref([])

        const get_hot_label = () => {
            $.ajax({
                url: store.state.BaseUrl + "/api/label/common/hot/list/",
                type: "get",
                success(resp) {
                    if (resp.code == 1) {
                        hotLabelList.value = resp.content;
                    } else {
                        alert("没有更多的标签了")
                    }

                },
            })
        }

        const hotArticleList = ref([]);
        const get_hot_article = () => {
            $.ajax({
                url: store.state.BaseUrl + "/api/article/common/hot/list/",
                type: "get",
                success(resp) {
                    if (resp.code == 1) {
                        hotArticleList.value = resp.content.articleList;
                    } else {
                        // alert("没有更多的标签了")
                    }

                },
            })
        }

        get_hot_article()
        get_hot_label()
        pull_page(currentPage)


        let hitokoto = ref("");
        const get_str = () => {
            hitokoto.value = '';

            
            $.ajax({
                type: 'get',
                url: "https://v2.alapi.cn/api/shici",
                data: {
                    type: "all",//
                    token: store.state.music.token,
                    
                    
                },
                success(resp) {
                    // if(resp.data.hitokoto.length > 30){
                    //     return
                    // }
                    hitokoto.value = resp;
                    // console.log(resp)
                }
            })


        }

        get_str();

        return {
            hitokoto,
            hotLabelList,
            hotArticleList,
            click_page,
            articleList,
            showPage,
            timestampToTime,
            hasTittle,
            pull_page,
            show_label_article,
            totalCount,
            gotoPageNum,
            toPageNum
        }


    }
}


</script>

<style scoped>

/* img {
  opacity: 0;
}
img:hover{
    opacity: 1;
} */
.article-side-padding {
    /* background-color: white; */
    /* padding-left: 2vw; */
    /* border-radius: 0.3%; */
    position: sticky;
    top: 50px;
    font-family: "微软雅黑";
    /* max-width: 20vw; */

}

.right-tag-padding {
    font-family: "微软雅黑";
    /* background-color: rgba(255, 255, 255, 0.2); */

    color: white;
    font-size: medium;
    /* border: 1px solid rgb(216, 211, 192); */

    /* border-radius: 1%; */
    padding-right: 1vw;
    height: 15vh;
    overflow-y: auto;
    /* width: 17vw; */
}

.right-article-padding {
    /* background-color: rgba(255, 255, 255, 0.2); */

    /* border: 1px solid rgb(175, 255, 151); */

    font-size: large;
    max-height: 60vh;
    overflow-y: auto;
    /* position: fixed; */
}

.pre-text {
    background-color: rgba(0, 0, 0, 0);
    color: #ededed;
}

.opacity-for-img{
    opacity: 0.95;
}

.opacity-for-img:hover {
    opacity: 1;
}

/* 鼠标悬停出现下划线 */
.mid-text:hover {

    opacity: 1;
    

    color: black;
    background-color: rgba(255, 255, 255, 0.99)
}

.right-text:hover {

    border: 2px solid rgb(255, 255, 255);
}

.hr-wavy {
    border: 0;
    padding: 3px;
    background: repeating-linear-gradient(135deg, black 0px, #0358f7 1px, transparent 1px, transparent 6px);
}

div::-webkit-scrollbar {
    width: 8px;
    height: 1px;
}

div::-webkit-scrollbar-thumb {
    background: grey;
}

div::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px white;
}
</style>