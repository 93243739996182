import { createRouter, createWebHistory } from 'vue-router'
import HomePageView from '../views/page/HomePageView.vue'
import UserListView from '../views/zelse/UserListView.vue'

import UserProfileView from '../views/user/UserProfileView.vue'
import LoginView from '../views/user/LoginView.vue'
import RegisterView from '../views/user/RegisterView.vue'
import ModifyProfileView from '../views/user/ModifyProfileView.vue'
import FriendProfileView from '../views/user/FriendProfileView.vue'
import MyBackgroundView from '../views/user/MyBackgroundView.vue'

import ArticleView from '../views/article/ArticleShowView.vue'
import ArticleDraft from '../views/article/ArticleDraftView.vue'
import ArticleWrite from '../views/article/ArticleWriteView.vue'
import ArticleManage from '../views/article/ArticleManageView.vue'
import ArticleModify from '../views/article/ArticleModifyView.vue'

import LabelArticleList from '../views/article/LabelArticleListView.vue'

import AdminArticle from '../views/admin/AdminArticleView.vue'
import AdminComment from '../views/admin/AdminCommentView.vue'
import AdminUser from '../views/admin/AdminUserView.vue'
import AdminLabel from '../views/admin/AdminLabelView.vue'



const routes = [


  //文章部分
  {
    path: '/article',
    name: 'article',
    component: ArticleView,
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },
  {
    path: '/article-draft',
    name: 'article_draft',
    component: ArticleDraft,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },
  {
    path: '/article-write',
    name: 'article_write',
    component: ArticleWrite,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },
  {
    path: '/article-manage',
    name: 'article_manage',
    component: ArticleManage,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },
  {
    path: "/article-modify",
    name: 'article_modify',
    component: ArticleModify,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }
  },
  {
    path: "/label-article-list",
    name: 'label_article_list',
    component: LabelArticleList,
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }
  }
  ,

  //个人
  {
    path: '/user-list',
    name: 'user_list',
    component: UserListView,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },
  {
    path: '/user-profile',
    name: 'user_profile',
    component: UserProfileView,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },
  {
    path: '/friend',
    name: 'friend',
    component: FriendProfileView,
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },
  {
    path: '/modifyProfile',
    name: 'modify_profile',
    component: ModifyProfileView,
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },

  //管理员
  {
    path: '/admin-article',
    name: 'admin_article',
    component: AdminArticle,
    meta: {
      requestAuth: true,
      requestAdmin: true,
    }

  },
  {
    path: '/admin-comment',
    name: 'admin_comment',
    component: AdminComment,
    meta: {
      requestAuth: true,
      requestAdmin: true,
    }

  },
  {
    path: '/admin-label',
    name: 'admin_label',
    component: AdminLabel,
    meta: {
      requestAuth: true,
      requestAdmin: true,
    }

  },
  {
    path: '/admin-user',
    name: 'admin_user',
    component: AdminUser,
    meta: {
      requestAuth: true,
      requestAdmin: true,
    }

  },
  {
    path: '/study',
    name: 'studyPlan',
    component: () => import("@/views/post/StudyView.vue")
  },

  //
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requestAuth: false,
      requestAdmin: false,
    }

  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },

  {
    path: '/myBg',
    name: "myBg",
    component: MyBackgroundView,
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }
  }
  ,
  {
    path: '/else',
    name: 'else',
    component: () => import("../views/page/ElsePageView.vue"),
    meta: {
      requestAuth: true,
      requestAdmin: false,

    }

  },
  {
    path: '/404',
    name: 'error',
    component: () => import("../views/page/ErrorPageView.vue"),
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },
  {
    path: '/music',
    name: 'music',
    component: () => import("../views/addition/MusicView.vue"),
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },

  {
    path: '/timer',
    name: 'timer',
    component: () => import("../views/addition/TimerView.vue"),
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },

  {
    path: '/',
    name: 'home',
    component: HomePageView,
    meta: {
      requestAuth: false,
      requestAdmin: false,

    }

  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router



// router.beforeEach((to, from, next) => {
//   if (to.name == "register" || to.name == "login") {
//     next();
//   } else if (!store.state.user.is_login) {
//     next({ name: "login" })
//   } else {
//     next();
//   }
// })

import store from '@/store/index'
router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth) {
    if (!store.state.user.is_login) {
      next({ name: "login" })
    } else if (!to.meta.requestAdmin || (to.meta.requestAdmin && store.state.user.is_admin)) {
      next();
    } else {
      next({ name: "home" });
    }
  }
  else {
    next();
  }
})

