<template>
    <!-- <div class="myContainer " id="wayBackTop" v-if="screenWidth >= 600" oncopy="alert('对不起，本文章不允许复制');return false"> -->
        <div id="mouse-line" style="opacity: 0.99; z-index: -1; position: fixed;">
            <vue-particles 
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="60"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.5"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    
    </vue-particles>
        </div>
    <div class="myContainer "  id="wayBackTop" v-if="screenWidth >= 1000">

        <div class="container" style="background-color:  rgba(207, 207, 207, 0.1);">
            <div class="row">

                <div class="col-9 ">
                    <div class="article-show-padding">

                        <h2 style="padding-left: 2vw; padding-top: 3vh;"><b>{{ tittle }}</b></h2>

                        <br>
                        <hr class="hr-solid-content" :data-content="'作者：' + author + ' 热度：' + article.viewCount + ' 上次修改：' + timestampToTime(Date.parse(new
        Date(article.updateTime).toString()))">
                        <img :src="article.bgUrl" style=" height: auto; width: 100%; overflow:hidden ; "
                            class="opacity-for-img" onerror="this.src='bulb.png';">
                            <!-- <div style="font-size: xx-large; font-weight: 600;"> -->
                                <v-md-preview :text="text"  ref="preview" />
                            <!-- </div> -->

                        <hr class="hr-solid-content" :data-content="timestampToTime(Date.parse(new
        Date(article.createTime).toString()))">

                        <div style="text-align: center;">
                            <router-link style="text-decoration: none; color: black;"
                                :to="{ name: 'friend', query: { friendId: article.authorId } }">
                                作者：<kbd>{{ author }} </kbd>&nbsp;
                                热度：<mark>{{ article.viewCount }}</mark>

                            </router-link>

                        </div>

                    </div>
                </div>
                <div class="col-3">
                    <div class="article-side-padding" style="opacity: 0.99; ">
                        <div class="right-tag-padding" v-if="labels.length > 0" style="padding-left: 1vw; ">
                            <!-- <p style="text-align: center; color:white;">文章标签</p> -->
                            <p style="text-align: center; color:white">文章标签</p>
                            <span v-for="label in labels" :key="label.id">
                                <router-link style=" text-decoration: none; color: white;"
                                    :to="{ name: 'label_article_list', query: { labelName: label, myArticle: 0 } }">
                                    <kbd style="font-size: medium;">{{ label }}
                                    </kbd></router-link>
                                &nbsp;
                            </span>
                        </div>
                        <!-- <hr v-if="labels.length > 0" class="hr-wavy"> -->
                        <p style="text-align: center; color:white">目录导航</p>

                        <div class="right-toc-padding" style="position: sticky; top: 10px; max-height: 40vh;">

                            <div v-for="anchor in titles" :key="anchor.id"
                                :style="{ padding: `1px 0 1px ${anchor.indent * 20}px` }"
                                @click="handleAnchorClick(anchor)">
                                <a href="#" style="font-size: medium; ">
                                    <kbd>
                                        {{ anchor.title }}
                                    </kbd>
                                </a>
                            </div>
                            <a style="text-decoration: underline; font-size: large;" href="#wayBackTop"><code>
                                    <kbd>回到顶部</kbd></code></a>
                        </div>
                        <!-- <hr class="hr-wavy"> -->

                        <p style="text-align: center; color:white">作者文章</p>

                        <div class="right-article-padding" v-if="articleHot.length > 0">

                            <div style="padding-left: 1vw; ">
                                <div v-for="articleItem in articleHot" :key="articleItem.id">
                                    <a href="#" style="color:#5c5a5b; font-size: medium; "
                                        @click="goto_article(articleItem.article.articleId, articleItem.article.authorId)">
                                        <kbd>{{ articleItem.article.tittle }}</kbd><span style="font-size: small;">
                                            <mark>热度{{
        articleItem.article.viewCount
    }}</mark></span></a>
                                </div>
                            </div>
                        </div>
                        <hr class="hr-wavy">
                        <hr class="hr-wavy">
                        <hr class="hr-wavy">
                        <hr class="hr-wavy">
                        <hr class="hr-wavy">


                        <div class="right-comment-padding" v-if="$store.state.user.is_login && 0">
                            <p style="text-align: center;">评论</p>

                            <div v-for="comment in comments" :key="comment.id">
                                <a style="font-size: 10px;"> {{ timestampToTime(Date.parse(new
        Date(comment.comment.createTime).toString())) }} </a><br>
                                <a v-if="comment.comment.authorId == $store.state.user.id"
                                    style="color:#3c0ac5; font-size: 12px;"><kbd
                                        style="color:rgb(231, 136, 141);">我</kbd>：</a>
                                <a v-else style="color:#3c0ac5; font-size: 12px;"><kbd
                                        style="color: rgb(244, 247, 247);">{{
        comment.author
    }}</kbd>：</a>
                                <a style="font-size: 12px;">{{ comment.comment.comment }}
                                </a>
                                &nbsp;
                                <a @click="del_comment(comment.comment.commentId)"
                                    v-if="comment.comment.authorId == $store.state.user.id"
                                    style="color:#3c0ac5; font-size: 12px; text-decoration: underline;">删除</a>
                            </div>
                        </div>
                        <div v-if="$store.state.user.is_login && 0">
                            <input placeholder="发表个评论吧！" v-model="comment" style="float: left; width: 70%;">
                            <button @click="send_comment" class="btn btn-info btn-sm" style="width: 30%;">发表</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>


    <!-- 手机端============================================================== -->

    <div class="myContainer " id="wayBackTop" v-else style="min-height: 1027px;">
        <a style="text-decoration: underline; font-size: large; 
    z-index: 100; float: right; padding-right: 5vw; position: sticky; top: 93vh; scroll-behavior: smooth"
            href="#wayBackTop">
            <!-- <mark style="background-color: white; border-radius: 10%;">顶部</mark> -->
            <img src="/ico/up.png" style="height: 30px;">
        </a>
        <div style="padding-left: 5vw;  max-height: 200px;  ">
            标题：<kbd>{{ tittle }}</kbd>
        </div>
        <hr class="hr-wavy">

        <div style="padding-left: 5vw;  max-height: 200px;  overflow-y: auto;">
            <!-- <div style="background-color: rgba(255, 255, 255, 0.5);"> -->

            目录：
            <!-- <div class="card"> -->
            <!-- <div class="card-body"> -->
            <!-- <p style="text-align: center;">目录导航</p> -->

            <div v-for="anchor in titles" :key="anchor.id" :style="{ padding: `1px 0 1px ${anchor.indent * 20}px` }"
                @click="handleAnchorClick(anchor)">
                <a href="#" style="font-size: medium; padding: 10vw;"><kbd>{{ anchor.title }}</kbd></a>
            </div>
            <a style="text-decoration: underline; font-size: large; padding: 10vw;"
                href="#wayBackTop"><code>回到顶部</code></a>
            <!-- </div> -->
            <!-- </div> -->
            <!-- </div> -->
        </div>
        <hr class="hr-wavy">
        <div style="padding-left: 5vw; padding-right: 10vw;">
            <!-- <div style="background-color: rgba(255, 255, 255, 0.5);"> -->

            标签：
            <span v-for="label in labels" :key="label.id">
                <router-link style=" text-decoration: none; color: black;"
                    :to="{ name: 'label_article_list', query: { labelName: label, myArticle: 0 } }">
                    <kbd>{{ label }}
                    </kbd></router-link>
                &nbsp;
            </span>
            <!-- </div> -->
        </div>
        <hr class="hr-wavy">

        <div class="" style="padding: 1vw; opacity: 0.99;">
            <!-- <div class="card">
                <div class="card-body"> -->
            <div style="background-color: white; overflow-x:scroll;">


                <div style="width: 100%; word-break: break-all; ">
                    <h2 style="padding-left: 2vw; padding-top: 3vh;"><b>{{ tittle }}</b></h2>

                    <hr class="hr-solid-content" :data-content="'作者：' + author + ' 热度：' + article.viewCount">

                    <div style="font-size: smaller;">
                        <v-md-preview style="font-size: smaller;" :text="text" ref="preview" />
                    </div>
                    <div style="text-align: center;">
                        <router-link style="text-decoration: none; color: black;"
                            :to="{ name: 'friend', query: { friendId: article.authorId } }">
                            作者：<kbd>{{ author }} </kbd>&nbsp;
                            热度：<mark>{{ article.viewCount }}</mark>

                        </router-link>

                    </div>

                    <hr class="hr-solid-content" :data-content="'发布：' + timestampToTime(Date.parse(new
        Date(article.createTime).toString()))">
                    <hr class="hr-solid-content" :data-content="'上次修改：' + timestampToTime(Date.parse(new
        Date(article.updateTime).toString()))">

                </div>
            </div>
            <!-- </div>
            </div> -->
        </div>

        <hr class="hr-wavy">

        <div class="" style="padding-left: 5vw; padding-right: 5vw;">
            <!-- <div class="card">
                <div class="card-body"> -->
            <div class="" v-if="$store.state.user.is_login">
                <p style="text-align: center;">评论</p>

                <div v-for="comment in comments" :key="comment.id">
                    <a style="font-size: 8px;"> {{ timestampToTime(Date.parse(new
        Date(comment.comment.createTime).toString())) }} </a><br>
                    <a v-if="comment.comment.authorId == $store.state.user.id"
                        style="color:#3c0ac5; font-size: 12px;"><kbd style="color:rgb(231, 136, 141);">我</kbd>：</a>
                    <a v-else style="color:#3c0ac5; font-size: 12px;"><kbd style="color: rgb(244, 247, 247);">{{
        comment.author
    }}</kbd>：</a>
                    <a style="font-size: 12px;">{{ comment.comment.comment }}
                    </a>
                    &nbsp;
                    <a @click="del_comment(comment.comment.commentId)"
                        v-if="comment.comment.authorId == $store.state.user.id"
                        style="color:#3c0ac5; font-size: 12px; text-decoration: underline;">删除</a>
                </div>
            </div>

            <!-- </div>
            </div> -->

            <div v-if="$store.state.user.is_login">
                <!-- <input placeholder="发个评论吧！" v-model="comment" style="float: left; width: 70%;">
                <button @click="send_comment" class="btn btn-info btn-sm" style="width: 30%;">发表</button> -->

                <br>
                <form class="d-flex" style="width: 100%; display: flex;
  align-items: center;justify-content: center; opacity:0.7; ">
                    <textarea v-model="comment" style="" rows="1" class="form-control me-2" type="search"
                        :placeholder="'发个评论吧！'" aria-label="Search">
                        </textarea>
                    <button class="btn btn-secondary" type="button" @click="send_comment">评</button>
                </form>

            </div>
            <hr class="hr-wavy">

            <!-- <div class="card">
                <div class="card-body"> -->
            <div class="" v-if="articleHot.length > 0">
                <p style="text-align: center;">作者文章推荐：</p>

                <div style="padding-left: 1vw; ">
                    <div v-for="articleItem in articleHot" :key="articleItem.id">
                        <a href="#" style="color:#5c5a5b; font-size: medium; "
                            @click="goto_article(articleItem.article.articleId, articleItem.article.authorId)">
                            <kbd>{{ articleItem.article.tittle }}</kbd><span style="font-size: small;">
                                <mark>热度{{
        articleItem.article.viewCount
    }}</mark></span></a>
                    </div>
                </div>
            </div>
            <!-- </div>
            </div> -->
            <hr class="hr-wavy">
            <!-- <hr class="hr-wavy">
            <hr class="hr-wavy">
            <hr class="hr-wavy">
            <hr class="hr-wavy">
            <hr class="hr-wavy">
            <hr class="hr-wavy">
            <hr class="hr-wavy"> -->

        </div>

    </div>
</template>

<script>
// import BlankBG from "@/components/BlankBG.vue"

import $ from 'jquery'

import router from '@/router';
// import { useStore } from "vuex";
import store from '@/store';
// import { ref } from 'vue';

export default {
    name: "ArticePage",
    components: {
        // BlankBG,
    },
    data() {
        return {
            text: "![zuoguai240314zkR.png](https://img.alowlife.com/i/3/2024/03/14/zuoguai240314zkR-3.webp)",
            titles: "",
            article: "",
            author: "作怪",
            // tittle:"",
            tittle: "糟糕，页面未找到",

            authorId: "",
            articleId: "",

            labels: [],
            comments: [],
            articleHot: [],

            comment: "",
            screenWidth: 1024,

        };
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$route.query.authorId != this.authorId || this.$route.query.articleId != this.articleId) {//需要监听的参数
                    this.articleId = this.$route.query.articleId
                    this.authorId = this.$route.query.authorId
                    this.show_labels(this.articleId)
                    this.show_article(this.articleId)
                    this.get_hot_list()
                    this.get_comment_list()
                    this.loadAuthorInfo(this.authorId)
                }
            }
        },
    },


    mounted() {
        this.screenWidth = window.innerWidth;
        console.log("屏幕宽度：" + this.screenWidth);


        //版权信息
        document.oncopy = function addLink(e) {
            e.preventDefault();
            //获取复制的文本内容
            const selection = window.getSelection();



            //获取当前网页地址
            const localLink = document.location.href;


            var appendLink = '';
            if (store.state.friend.id == store.state.user.id) {
                appendLink = `\n\r
作者：` + store.state.user.username + `
链接：${localLink}
来源：alowlife.com
著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。 `
            } else {
                appendLink = `\n\r
作者：` + store.state.friend.username + `
链接：${localLink}
来源：alowlife.com
著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。 `
            }

            let copyText = selection;
            if (copyText.toString().length >= 20) {
                copyText = selection + appendLink;
            }
            if (e.clipboardData) {
                e.clipboardData.setData('text', copyText)
            } else {
                //在ie中 clipboardData是window的属性
                window.clipboardData.setData('text', copyText);
            }
        }
    },
    methods: {

        // addCopyText() {
        //     var text = window.clipboardData.getData("text")
        //     alert("不准复制" + text)
        // },

        // 时间戳：1637244864707
        /* 时间戳转换为时间 */
        timestampToTime(timestamp) {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },

        loadAuthorInfo(authorId) {
            const that = this
            // if (authorId == store.state.user.id) {
            //     that.author = store.state.user.username;

            //     return;
            // } else 


            if (authorId == store.state.friend.id) {
                that.author = store.state.friend.username
                return;
            }

            store.dispatch("getFriendInfo", {
                friendId: authorId,
                success() {
                    that.author = store.state.friend.username
                },
                error() {
                    that.author = "作怪"
                }

            })



        },

        del_comment(commentId) {
            const that = this
            $.ajax({
                url: store.state.BaseUrl + "/api/comment/" + commentId,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        // console.log(resp)
                        that.get_comment_list()

                    } else {
                        console.log(resp)
                    }
                }

            })
        },


        send_comment() {
            const that = this

            that.comment = that.comment.trim()
            if (that.comment.length <= 2) {
                alert("评论不能少于两个字符")
                return
            } else if (that.comment.length >= 100) {
                alert("评论不能长于一百个字符")
                return
            }
            $.ajax({
                url: store.state.BaseUrl + "/api/comment/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                    comment: that.comment,
                    answerArticleId: that.articleId,
                    authorId: store.state.user.id

                },
                success(resp) {
                    if (resp.code == 1) {
                        // console.log(resp)
                        that.comment = ""
                        that.get_comment_list()

                    } else {
                        console.log(resp)
                    }
                }

            })
        },

        get_comment_list() {
            const that = this
            $.ajax({
                url: store.state.BaseUrl + "/api/comment/" + that.articleId,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        // console.log(resp)
                        that.comments = resp.content
                    } else {
                        console.log(resp)
                    }
                },
                error(resp) {
                    console.log(resp)
                }

            })
        },

        goto_article(articleId, authorId) {

            if (this.articleId == articleId) {
                // console.log("当前文章展示")
                return
            }

            router.push({
                path: "/article",
                query: {
                    authorId: authorId,
                    articleId: articleId
                }
            })
        },

        handleAnchorClick(anchor) {
            const { preview } = this.$refs;
            const { lineIndex } = anchor;

            const heading = preview.$el.querySelector(`[data-v-md-line="${lineIndex}"]`);

            if (heading) {
                preview.scrollToTarget({
                    target: heading,
                    scrollContainer: window,
                    top: 60,
                });
            }
        },

        show_labels(articleId) {
            // const store = useStore()
            const that = this
            $.ajax({
                url: store.state.BaseUrl + "/api/label/common/" + articleId,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        that.labels = resp.content;
                        // console.log("获取标签成功:" + that.labels)
                    } else {
                        console.log(resp.content)
                    }
                }, error() {
                    console.log("获取标签失败")
                }

            })
        },
        show_article(articleId) {
            const that = this

            if (store.state.user.is_login) {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/" + articleId,
                    type: "GET",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token
                    },
                    success(resp) {

                        if (resp.code == 1) {

                            that.text = resp.content.content
                            that.article = resp.content
                            that.authorId = resp.content.authorId
                            that.tittle = resp.content.tittle

                            // console.log(resp)


                            setTimeout(that.test, 1000);
                        } else {
                            console.log("获取文章失败")
                            that.text = "![zuoguai240314zkR.png](https://img.alowlife.com/i/3/2024/03/14/zuoguai240314zkR-3.webp)"
                            that.tittle = "页面未找到"
                            // router.push({ name: "error" })
                        }


                    },
                    error(resp) {
                        console.log(resp)
                    }

                })
            } else {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/common/" + articleId,
                    type: "GET",
                    // headers: {
                    //     Authorization: "Bearer " + store.state.user.token
                    // },
                    success(resp) {

                        if (resp.code == 1) {

                            that.text = resp.content.content
                            that.article = resp.content
                            that.authorId = resp.content.authorId
                            that.tittle = resp.content.tittle
                            console.log(resp)
                            setTimeout(that.test, 1000);
                        } else {
                            console.log("获取文章失败")
                            that.text = "![zuoguai240314zkR.png](https://img.alowlife.com/i/3/2024/03/14/zuoguai240314zkR-3.webp)"
                            that.tittle = "页面未找到"
                            // router.push({ name: "error" })
                        }


                    },
                    error(resp) {
                        console.log(resp)
                    }

                })
            }

        },

        get_hot_list() {
            const that = this
            // const store = useStore()
            $.ajax({
                //热门文章
                url: store.state.BaseUrl + "/api/article/common/hot/" + 1 + "/" + that.authorId,
                type: "get",
                // headers: {
                //     Authorization: "Bearer " + store.state.user.token,
                // },

                success(resp) {

                    that.articleHot = resp.content.articleList;
                    // console.log(that.articleHot)


                },

            })

        },
        test() {

            const anchors = this.$refs.preview.$el.querySelectorAll('h1,h2,h3,h4,h5,h6');
            const titles = Array.from(anchors).filter((title) => !!title.innerText.trim());

            if (!titles || !titles.length) {
                this.titles = [];
                return;
            }

            const hTags = Array.from(new Set(titles.map((title) => title.tagName))).sort();

            this.titles = titles.map((el) => ({
                title: el.innerText,
                lineIndex: el.getAttribute('data-v-md-line'),
                indent: hTags.indexOf(el.tagName),
            }));
        }
    },

    setup() {

        // var author store.state.friend.username;




    }

}

</script>


<style scoped>

#mouse-line{
    width: 100%;
    height: 100%

}
kbd:hover {
    /* text-decoration: underline; */
    border: 2px solid rgb(255, 255, 255);
}

.myContainer {
    /* padding-top: 4vh; */
    /* padding-left: 4vw; */
    opacity: 1;

}

.left-toc-top-padding {
    /* background-color: white; */
    height: 15vh;
    width: 17vw;
    /* overflow-y: scroll; */
    padding-left: 1vw;
}


.left-toc-padding {
    /* border-radius: 2%; */

    height: 30vh;
    overflow-y: auto;
    width: 100%;
}

.article-show-padding {
    /* background-color: white; */
    /* background-color:  rgba(207, 207, 207, 0.2); */
    color: white;
    /* border-radius: 0.1%; */
    min-height: 50vh;
    opacity: 1;
}

.article-side-padding {
    /* background-color: rgba(0, 0, 0, 0.2); */
    position: sticky;
    top: 5vh;
}

.right-tag-padding {
    max-height: 20vh;
    overflow-y: auto;
    overflow: hidden;
    /* padding-left: 1vw; */
    padding-right: 1vw;
    /*隐藏滚动条但不取消滚动功能 */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /*火狐 */
    /* background-color: rgba(255, 255, 255, 0.5); */
    color: white;

    /* border: 1px solid green; */

}

.right-toc-padding {
    padding-right: 1vw;
    padding-left: 1vw;
    max-height: 40vh;

    overflow-y: auto;

    /* background-color: rgba(255, 255, 255, 0.5); */
    color: white;
    position: relative;

    /* border: 1px solid green; */
}


.right-article-padding {

    padding-right: 1vw;
    max-height: 25vh;
    overflow-y: auto;

    /* background-color: rgba(255, 255, 255, 0.5); */

    color: white;
    /* border: 1px solid green; */
}

.right-comment-padding {
    /* border-radius: 2%; */
    /* padding-right: 1vw; */
    padding-top: 100px;
    padding-left: 1vw;
    padding-bottom: 1vh;
    height: 25vh;
    overflow-y: auto;
    /* background-color: rgba(255, 255, 255, 0.5); */

    color: white;
    border: 1px solid green;
}


a {
    color: white;
    /*字体颜色*/
    font-size: small;
    font-weight: 1000;
    text-decoration: none;
    /*去除a标签下划线*/
    /* outline: none; */
    /*去掉a标签默认轮廓*/
}

/* p {
    font-weight: 50;
    color: black;
} */

.hr-solid-content {
    /* color: #a2a9b6; */
    color: black;
    border: 0;
    font-size: 12px;
    padding: 1em 0;
    position: relative;
}

.hr-solid-content::before {
    content: attr(data-content);
    position: absolute;
    padding: 0 1ch;
    line-height: 1px;
    border: solid #d0d0d5;
    border-width: 0 99vw;
    width: fit-content;
    /* for IE浏览器 */
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
}

.hr-solid-content::after {
    content: attr(data-content);
    position: absolute;
    padding: 4px 1ch;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;
    border: 1px solid #d0d0d5;
}

.hr-wavy {
    border: 0;
    padding: 3px;
    background: repeating-linear-gradient(135deg, black 0px, #0358f7 1px, transparent 1px, transparent 6px);
}


/* 禁止复制文章 */
/* div {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
} */


.right-toc-padding::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}


.right-toc-padding::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    /* border-radius: 10px; */
    /* background: #9e9e9e; */
    background: grey;
    /* box-shadow: inset 0 0 5px rgba(0, 122, 204); */
    /* box-shadow: inset 0 0 5px black; */

}

/* 滚动条底层颜色! */
.right-toc-padding::-webkit-scrollbar-track {
    /* border-radius: 10px; */
    background: #ededed;

    /*滚动条里面轨道*/
    /* box-shadow: inset 0 0 5px rgba(0, 0, 0, .1); */
    box-shadow: inset 0 0 5px white;
}





div::-webkit-scrollbar {
    width: 8px;
    height: 1px;
}

div::-webkit-scrollbar-thumb {
    background: grey;
}

div::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px white;
}
</style>