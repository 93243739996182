<template>
    <div class="container" v-if="screenWidth >= 600">
        <div class="row" style="padding-top: 3vh; opacity: 0.99;">
            <div class="col-3">
                <div style="width: 100%;  border-radius: 0%; position: sticky; top:30px;">
                    <div class="left-avator-padding" style="border: 1px solid skyblue">
                        <div class="row">
                            <div class="col-6">
                                <div style="float: left;">
                                    <img :src="$store.state.friend.avatar" style="width: 6vw; height: 6vw; ">
                                </div>
                            </div>
                            <div class="col-6">
                                <div style="text-align: center; padding-top: 0vh;">


                                    <span v-if="$store.state.friend.username.length < 5"><b>{{ $store.state.friend.username
                                    }}</b></span>
                                    <span v-else>
                                        <small style="font-weight: bolder;"><b>{{ $store.state.friend.username
                                        }}</b></small>
                                    </span>
                                    <br>
                                    <small>粉丝：500亿</small>
                                    <br>
                                    <button type="button" class="btn btn-outline-dark btn-sm">关注</button>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                    <div style="padding-top: 1px;"></div>


                    <div class="left-proverb-padding" style="border: 1px solid skyblue">
                        简介：<br>
                        {{ $store.state.friend.proverb }}

                    </div>
                    <div style="padding-top: 1px;"></div>


                    <!-- <div class="left-article-padding">
                        热门文章：
                        <div v-for="articleItem in articleHot" :key="articleItem.id">
                            <router-link style=" text-decoration: none; color: black;"
                                :to="{ name: 'article', query: { articleId: articleItem.article.articleId, authorId: $store.state.friend.id } }">
                                <kbd style="color: azure; font-size: small;">{{ articleItem.article.tittle
                                }}
                                </kbd>
                                <span style="font-size: small;">
                                    ——热度<mark>{{
                                        articleItem.article.viewCount
                                    }}</mark>
                                </span>
                            </router-link>
                        </div>

                    </div>
                    <hr class="hr-wavy"> -->

                    <div class="left-article-padding" style="border: 1px solid skyblue">
                        标签:
                        <br>
                        <span v-for="label in labelList" :key="label.id">
                            <router-link style=" text-decoration: none; color: black;"
                                :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 0 } }"><kbd
                                    style="color: azure; font-size: small; ">{{
                                        label.labelName }}
                                </kbd>*{{ label.labelCount
                                }}</router-link>&nbsp;
                        </span>
                    </div>
                    <!-- <hr class="hr-wavy">

                    <div class="left-comment-padding">
                        最近评论：
                        <div v-for="comment in commentList.commentList" :key="comment.id">
                            <a style="font-size: 10px; text-decoration: none; color: black;">
                                {{ timestampToTime(Date.parse(new
                                    Date(comment.comment.createTime).toString())) }}
                            </a>
                            <br>
                            <a style="font-size: 12px;">{{ comment.comment.comment }}
                            </a>
                            &nbsp;

                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-9">
                <nav class="navbar navbar-light bg-light" style="border: 1px solid skyblue;">
                    <div class="container-fluid">
                        <a class="navbar-brand">发布文章：{{ articleCount }} 篇</a>
                    </div>
                </nav>

                <div style="width: 100%;">
                    <text v-if="error_message != ''" style="color: red; font-size: 50px;">{{ error_message
                    }}</text>
                    <div class="list-group">
                        <div v-for="articleItem in articleList" :key="articleItem.id">
                            <div style="padding-top: 1px;"></div>

                            <a href="#" @click="show_article(articleItem.article.articleId)"
                                class="list-group-item list-group-item-action " style="border: 1px solid skyblue">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                                    <small>{{ new Date(articleItem.article.createTime).toLocaleDateString() }}</small>
                                </div>
                                <p class="mb-1">简介：{{ articleItem.article.brief }}
                                </p>

                                <span>
                                    <button type="button" @click="show_article(articleItem.article.articleId)"
                                        class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>是否置顶：
                                    <small v-if="articleItem.article.top"><kbd>是</kbd></small>
                                    <small v-else><kbd>否</kbd></small>

                                </span>


                                <small style="margin: 10px;">文章热度：<kbd>{{ articleItem.article.viewCount }}</kbd></small>

                            </a>
                        </div>

                    </div>
                    <br>
                    <nav aria-label="Page navigation example">
                        <button class="btn btn-primary " style="float:right" @click="gotoPageNum()">跳转</button>
                        <input type="number" class="form-control" :placeholder="'共有' + totalPage + '页'"
                            style="width: 120px; float:right" v-model="toPageNum">
                        &nbsp;&nbsp;&nbsp;
                        <ul class="pagination  pagination-sm " style="float:right">
                            <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                            </li>
                            <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                                v-for=" page in showPage" :key="page.number">
                                <a class="page-link" href="#">{{ page.number }}</a>
                            </li>

                            <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>

    <!-- +++++++++++++++++++++++++++++手机端 -->

    <div v-else>

        <div style="padding: 1vw; border-radius: 0%; ">
            <div class="left-avator-padding">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <div style="float: left;">
                            <img :src="$store.state.friend.avatar" style="width: 120px; height: 120px; ">
                        </div>
                    </div>
                    <div class="col-6">
                        <div style="text-align: center; padding-top: 0vh;">
                            <text v-if="error_message != ''" style="color: red;">{{ error_message }}</text>

                            <span v-if="$store.state.friend.username.length < 5"><b>{{ $store.state.friend.username
                            }}</b></span>
                            <span v-else>
                                <small style="font-weight: bolder;"><b>{{ $store.state.friend.username
                                }}</b></small>
                            </span>
                            <br>
                            <small>粉丝：500亿</small>
                            <br>
                            <button type="button" class="btn btn-outline-dark btn-sm">关注</button>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <hr class="hr-wavy">

            <div class="left-proverb-padding" style="border: none; height: 120px;">
                简介：<br>
                {{ $store.state.friend.proverb }}
            </div>
            <hr class="hr-wavy">

            <!-- <div class="left-article-padding" style="border: none; height: 150px;">
                热门文章：
                <div v-for="articleItem in articleHot" :key="articleItem.id">
                    <router-link style=" text-decoration: none; color: black;"
                        :to="{ name: 'article', query: { articleId: articleItem.article.articleId, authorId: $store.state.friend.id } }">
                        <kbd style="color: azure; font-size: small;">{{ articleItem.article.tittle
                        }}
                        </kbd>
                        <span style="font-size: small;">
                            ——热度<mark>{{
                                articleItem.article.viewCount
                            }}</mark>
                        </span>
                    </router-link>
                </div>

            </div> -->
            <hr class="hr-wavy">

            <div class="left-article-padding" style="border: none; height: 100px;">
                标签:
                <br>
                <span v-for="label in labelList" :key="label.id">
                    <router-link style=" text-decoration: none; color: black;"
                        :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 0 } }"><kbd
                            style="color: azure; font-size: small; ">{{ label.labelName }}
                        </kbd>*{{ label.labelCount
                        }}</router-link>&nbsp;
                </span>
            </div>
            <hr class="hr-wavy">

            <!-- <div class="left-comment-padding">
                最近评论：
                <div v-for="comment in commentList.commentList" :key="comment.id">
                    <a style="font-size: 10px; text-decoration: none; color: black;">
                        {{ timestampToTime(Date.parse(new
                            Date(comment.comment.createTime).toString())) }}
                    </a>
                    <br>
                    <a style="font-size: 12px;">{{ comment.comment.comment }}
                    </a>
                    &nbsp;

                </div>
            </div> -->
        </div>
        <div style="padding:1vw; ">

            <nav class="navbar navbar-light ">
                <div class="container-fluid">
                    <a class="navbar-brand">发布文章：{{ articleCount }} 篇</a>
                </div>
            </nav>
        </div>

        <div style="width: 100%; padding:1vw; opacity: 0.99;">
            <div class="list-group">
                <div v-for="articleItem in articleList" :key="articleItem.id">
                    <a href="#" @click="show_article(articleItem.article.articleId)"
                        class="list-group-item list-group-item-action " style="border: 1px solid skyblue">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                            <small>{{ new Date(articleItem.article.createTime).toLocaleDateString() }}</small>
                        </div>
                        <p class="mb-1">简介：{{ articleItem.article.brief }}</p>

                        <span>
                            <button type="button" @click="show_article(articleItem.article.articleId)"
                                class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>是否置顶：
                            <small v-if="articleItem.article.top"><kbd>是</kbd></small>
                            <small v-else><kbd>否</kbd></small>

                        </span>


                        <small style="margin: 10px;">文章热度：<kbd>{{ articleItem.article.viewCount }}</kbd></small>

                    </a>
                </div>

            </div>
            <br>
            <nav aria-label="Page navigation example">
                <button class="btn btn-primary " style="float:right" @click="gotoPageNum()">跳转</button>
                <input type="number" class="form-control" :placeholder="'共有' + totalPage + '页'"
                    style="width: 120px; float:right" v-model="toPageNum">
                &nbsp;&nbsp;&nbsp;
                <ul class="pagination  pagination-sm " style="float:right">
                    <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                    </li>
                    <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                        v-for=" page in showPage" :key="page.number">
                        <a class="page-link" href="#">{{ page.number }}</a>
                    </li>

                    <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</template>
    
<script>

// import BlankBG from "@/components/BlankBG.vue"
import $ from 'jquery'
import router from '@/router';
import store from '@/store';
export default {
    name: "FriendProfile",
    components: {
        // BlankBG,
    },

    data() {
        return {
            friendId: 0,

            currentPage: 1,
            totalPage: 1,
            showPage: [],
            articleCount: "",
            articleList: [],
            articleHot: [],
            labelList: [],
            commentList: [],

            toPageNum: "",
            screenWidth: 1024,



        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$route.query.friendId) {//需要监听的参数
                    this.friendId = this.$route.query.friendId
                    this.loadAuthorInfo()
                    this.get_hot_list()
                    this.get_label_list()
                    this.get_comment_list()
                    this.pull_page(1)
                }
            }
        },

    },

    mounted() {
        this.screenWidth = window.innerWidth
    },


    methods: {

        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },

        loadAuthorInfo() {
            const that = this
            if (this.friendId == store.state.user.id) {
                router.push({ name: "user_profile" })
                return;
            } else if (this.friendId == store.state.friend.id) {

                return;
            }

            // console.log("拉取用户信息")
            store.dispatch("getFriendInfo", {
                friendId: this.friendId,
                success() {
                    that.error_message = ""
                },
                error(resp) {
                    that.error_message = resp
                }

            })



        },


        timestampToTime(timestamp) {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },

        show_article(articleId) {
            router.push({
                path: "/article",
                query: {
                    authorId: this.friendId,
                    articleId: articleId
                }
            })
        },

        update_pages() {
            // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
            let max_pages = this.totalPage;
            // console.log("最大页数" + max_pages)

            let new_page = [];
            for (let i = this.currentPage - 2; i < this.currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === this.currentPage ? "active" : "",
                    })
                }
            }

            this.showPage = new_page;

        },

        click_page(page) {
            if (page === -1) {
                page = this.currentPage + 1;

            } else if (page === -2) {
                page = this.currentPage - 1;
            }
            let max_pages = this.totalPage;
            if (page >= 1 && page <= max_pages) {
                console.log("获取页面：" + page)
                this.pull_page(page);
            }
        },
        pull_page(page) {
            this.currentPage = page;
            // console.log(this.currentPage)

            const that = this
            $.ajax({
                //url格式：article/list/页码/用户id/是否需要草稿/
                url: store.state.BaseUrl + "/api/article/common/hot/" + page + "/" + that.friendId,
                type: "get",
                // headers: {
                //     Authorization: "Bearer " + store.state.user.token,
                // },
                success(resp) {
                    that.articleList = resp.content.articleList;
                    that.articleCount = resp.content.totalCount
                    that.totalPage = resp.content.totalPage;
                    that.update_pages();
                },
            })
        },
        //获得热门文章

        get_hot_list() {
            const that = this
            $.ajax({
                //热门文章
                url: store.state.BaseUrl + "/api/article/common/hot/1/" + that.friendId,
                type: "get",
                // headers: {
                //     Authorization: "Bearer " + store.state.user.token,
                // },

                success(resp) {

                    that.articleHot = resp.content.articleList;
                },

            })
        },


        //获得标签列表
        get_label_list() {
            const that = this
            $.ajax({
                url: store.state.BaseUrl + "/api/label/common/list/" + that.friendId,
                type: "get",
                // headers: {
                //     Authorization: "Bearer " + store.state.user.token,
                // },

                success(resp) {

                    that.labelList = resp.content;
                    // console.log(labelList)

                    // alert(labelList.value)
                }, error() {

                }

            })
        },


        //获得评论列表

        get_comment_list() {
            const that = this
            $.ajax({
                url: store.state.BaseUrl + "/api/comment/author/" + that.friendId + "/" + 1,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        // console.log(resp)
                        that.commentList = resp.content
                        // console.log(commentList.value)
                    } else {
                        // console.log(resp)
                    }
                },
                // error(resp) {
                //     console.log(resp)
                // }
            })
        },

        gotoPageNum() {
            if (this.toPageNum <= 0 || this.toPageNum > this.totalPage) {
                this.toPageNum = 1
                alert("页码超出范围")
                return
            }
            this.pull_page(this.toPageNum)

        }


    },

    setup() {

        // const articleList = ref([])
        // const articleHot = ref([])
        // const labelList = ref([])
        // // const PAGE = 6;
        // let currentPage = 1;
        // let totalPage = 1;
        // let showPage = ref([]);


        // const timestampToTime = (timestamp) => {
        //     timestamp = timestamp ? timestamp : null;
        //     let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        //     let Y = date.getFullYear() + '-';
        //     let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        //     let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        //     let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        //     let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        //     let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        //     return Y + M + D + h + m + s;
        // }


        // const show_article = (articleId) => {
        //     router.push({
        //         path: "/article",
        //         query: {
        //             authorId: store.state.user.id,
        //             articleId: articleId
        //         }
        //     })
        // }


        // const update_pages = () => {
        //     // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
        //     let max_pages = totalPage;
        //     // console.log("最大页数" + max_pages)

        //     let new_page = [];
        //     for (let i = currentPage - 2; i < currentPage + 2; i++) {
        //         if (i >= 1 && i <= max_pages) {
        //             new_page.push({
        //                 number: i,
        //                 is_active: i === currentPage ? "active" : "",
        //             })
        //         }
        //     }

        //     showPage.value = new_page;

        // }

        // const click_page = (page) => {
        //     if (page === -1) {
        //         page = currentPage + 1;

        //     } else if (page === -2) {
        //         page = currentPage - 1;
        //     }
        //     let max_pages = totalPage;
        //     if (page >= 1 && page <= max_pages) {
        //         console.log("获取页面：" + page)
        //         pull_page(page);
        //     }
        // }

        // const articleCount = ref("")

        // const pull_page = (page) => {
        //     currentPage = page;

        //     $.ajax({
        //         //url格式：article/list/页码/用户id/是否需要草稿/
        //         url: store.state.BaseUrl + "/api/article/list/hot/" + page + "/" + store.state.user.id,
        //         type: "get",
        //         headers: {
        //             Authorization: "Bearer " + store.state.user.token,
        //         },

        //         success(resp) {

        //             articleList.value = resp.content.articleList;
        //             articleCount.value = resp.content.totalCount

        //             totalPage = resp.content.totalPage;
        //             update_pages();


        //         },
        //     })
        // }

        // //获得热门文章
        // $.ajax({
        //     //热门文章
        //     url: store.state.BaseUrl + "/api/article/list/hot/" + 1 + "/" + store.state.user.id,
        //     type: "get",
        //     headers: {
        //         Authorization: "Bearer " + store.state.user.token,
        //     },

        //     success(resp) {

        //         articleHot.value = resp.content.articleList;
        //     },

        // })

        // //获得标签列表
        // $.ajax({
        //     url: store.state.BaseUrl + "/api/label/list/ " + store.state.user.id,
        //     type: "get",
        //     headers: {
        //         Authorization: "Bearer " + store.state.user.token,
        //     },

        //     success(resp) {

        //         labelList.value = resp.content;
        //         // console.log(labelList)

        //         // alert(labelList.value)
        //     }, error() {

        //     }

        // })

        // //获得评论列表
        // const commentList = ref([])
        // const get_comment_list = () => {
        //     $.ajax({
        //         url: store.state.BaseUrl + "/api/comment/author/" + store.state.user.id + "/" + 1,
        //         type: "get",
        //         headers: {
        //             Authorization: "Bearer " + store.state.user.token,
        //         },
        //         success(resp) {
        //             if (resp.code == 1) {
        //                 // console.log(resp)
        //                 commentList.value = resp.content
        //                 // console.log(commentList.value)
        //             } else {
        //                 // console.log(resp)
        //             }
        //         },
        //         // error(resp) {
        //         //     console.log(resp)
        //         // }
        //     })
        // }

        // const del_comment = (commentId) => {
        //     $.ajax({
        //         url: store.state.BaseUrl + "/api/comment/" + commentId,
        //         type: "delete",
        //         headers: {
        //             Authorization: "Bearer " + store.state.user.token,
        //         },
        //         success(resp) {
        //             if (resp.code == 1) {
        //                 // console.log(resp)
        //                 get_comment_list()

        //             } else {
        //                 console.log(resp)
        //             }
        //         }

        //     })
        // }



        // const toPageNum = ref("");

        // const gotoPageNum = () => {
        //     if (toPageNum.value <= 0 || toPageNum.value > totalPage) {
        //         toPageNum.value = 1
        //         alert("页码超出范围")
        //         return
        //     }
        //     pull_page(toPageNum.value)

        // }

        // get_comment_list()
        // pull_page(currentPage)

        // return {
        //     gotoPageNum,
        //     toPageNum,
        //     articleList,
        //     labelList,
        //     click_page,
        //     showPage,
        //     show_article,
        //     articleHot,
        //     commentList,
        //     timestampToTime,
        //     del_comment,
        //     articleCount
        // }





    }



}


</script>
    
<style scoped>
.left-avator-padding {
    background-color: white;
    padding-top: 2vw;
    padding-left: 1vw;
    padding-right: 1vw;
    /* border: solid 1px white; */
}

.left-proverb-padding {
    background-color: white;
    padding-left: 1vw;
    padding-right: 1vw;
    /* border: solid 1px whitesmoke; */
    overflow-y: auto;
    font-size: small;
    height: 10vh;
    width: 100%;
    /* overflow-y: scroll; */
}

.left-article-padding {
    background-color: white;
    padding-left: 1vw;
    padding-right: 1vw;
    /* border: solid 1px whitesmoke; */
    font-size: small;

    height: 18vh;
    width: 100%;
    overflow-y: auto;

}

.left-comment-padding {
    background-color: white;
    padding-left: 1vw;
    padding-right: 1vw;
    /* border: solid 1px whitesmoke; */
    font-size: small;

    height: 10vh;
    width: 100%;
    overflow-y: auto;
}


.hr-wavy {
    border: 0;
    padding: 3px;
    background: repeating-linear-gradient(135deg, black 0px, #0358f7 1px, transparent 1px, transparent 6px);
}

div::-webkit-scrollbar {
    width: 8px;
    height: 1px;
}

div::-webkit-scrollbar-thumb {
    background: grey;
}

div::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px white;
}
</style>