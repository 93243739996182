<template>
    <div class="container" v-if="is_phone > 1000" style="min-height: 90vh;">

        <div class="row" style="margin-top: 5%;">

            <div class="col-8">
                <div style="color:red;" v-if="nonMusic != ''">
                    抱歉！{{ nonMusic }}
                </div>

                <div class="d-flex">

                    <input style="border-radius:0%; background-color:rgba(255, 255, 255, 0.2);" type="text"
                        class="form-control" v-model="songName" placeholder="搜索歌曲" name="search"
                        @keyup.enter="get_music_list()">
                    <button class="btn btn-info" type="button" @click="get_music_list"
                        style="margin-left: 2%; ">search</button>
                </div>

                <div class=" opacity-100" style="margin-top: 3%;">
                    <!-- <div class="" style="background-color:rgba(0, 0, 0, 0.9);"> -->
                    <div class="" style="background-color:rgba(255, 255, 255, 0.2);">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>歌曲</th>

                                    <th>歌手</th>
                                    <th></th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="song in songs" :key="song.id">

                                    <td>
                                        <span><kbd>{{ song.name }}</kbd></span>
                                    </td>
                                    <td>
                                        <span><kbd>{{ song.artists[0].name }}</kbd></span>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-dark" type="button"
                                            @click="get_music_info(song.id)">加载</button>
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
            <div class="col-4">
                <div style="background-color:white;" class="">
                    <img :src="$store.state.music.picUrl" class="card-img-top" alt="">
                    <div class=" text-center" v-if="$store.state.music.mp3Url != ''">


                        <audio :src="$store.state.music.mp3Url" autoplay="true" controls="">
                        </audio>
                        <br>

                        <kbd>歌曲名：{{ $store.state.music.name }}</kbd>
                        <kbd>作者：{{ $store.state.music.auther }}</kbd>
                        <br>
                        <!-- <button type="button" class="btn btn-info" @click="next_music">下一曲</button> -->

                        <button type="button" class="btn btn-info" @click="next_random_music">随机一曲</button>




                    </div>
                    <div v-else class="card-body text-center">
                        <!-- <div class="text-center"> -->
                        <button type="button" class="btn btn-info" @click="next_random_music">随机一曲</button>

                        <!-- </div> -->

                    </div>



                </div>

            </div>
        </div>

    </div>

    <div v-else class="container">

        <div class="container" style="margin-top: 10%;">
            <div style="color:red;" v-if="nonMusic != ''">
                抱歉！{{ nonMusic }}
            </div>
            <div style="background-color:white;" class=" ">
                <img :src="$store.state.music.picUrl" class="card-img-top" alt="">
                <hr>
                <div class=" text-center" v-if="$store.state.music.mp3Url != ''">


                    <audio :src="$store.state.music.mp3Url" autoplay="false" controls="">
                    </audio>
                    <br>

                    歌曲名：{{ $store.state.music.name }}
                    <br>
                    <button type="button" class="btn btn-info" @click="next_random_music">随机一曲</button>




                </div>
                <div v-else class="card-body text-center">
                    <!-- <div class="text-center"> -->
                    <button type="button" class="btn btn-info" @click="next_random_music">随机一曲</button>

                    <!-- </div> -->

                </div>


            </div>



        </div>
        <div style="margin-top: 5%;">
            <div class="d-flex">

                <input @keyup.enter="get_music_list()" style="border-radius:0%;" type="text" class="form-control"
                    v-model="songName" placeholder="搜索歌曲" name="search">
                <button class="btn btn-info" type="button" @click="get_music_list"
                    style="margin-left: 2%;">search</button>
            </div>

            <div class=" opacity-100" style="margin-top: 3%; ">
                <div class=" " style="border-radius:0%;">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="phone_th">歌曲</th>

                                <th class="phone_th">歌手</th>
                                <th class="phone_th"></th>



                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="song in songs" :key="song.id">

                                <td class="phone_td">
                                    <span><kbd>{{ song.name }}</kbd></span>
                                </td>
                                <td class="phone_td">
                                    <span><kbd>{{ song.artists[0].name }}</kbd></span>
                                </td>
                                <td class="phone_td">
                                    <button class="btn btn-sm btn-dark" type="button"
                                        @click="get_music_info(song.id)">加载</button>
                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import $ from "jquery"
// import $ from 'jquery'
export default {


    setup() {
        const store = useStore();
        let listen = ref("");
        let nonMusic = ref("");

        let is_phone = document.documentElement.clientWidth;

        listen.value = ''
        const next_random_music = () => {

            listen.value = '',
                store.dispatch(
                    "get_random_music", {
                    success() {
                        listen.value = store.state.music.mp3Url;
                        console.log(listen)
                    }
                }
                )



        }

        const songs = ref([]);
        let songName = ref("");
        let songIdx = ref("");
        songName.value = store.state.music.search_name
        const get_music_list = () => {
            nonMusic.value = ""

            if (songName.value == "") {
                alert("请输入歌曲名称")
            } else {
                store.commit("set_search_name", { search_name: songName.value });

                $.ajax({
                    type: "get",
                    url: "https://v2.alapi.cn/api/music/search",
                    data: {

                        keyword: songName.value,
                        limit: 10,
                        page: 1,
                        type: 1,
                        token: "W18jiqKwXqN1uKxV"

                    }, success(resp) {
                        console.log(resp)
                        if (resp.data == null) {
                            // alert("暂无" + songName.value + "资源..")
                            nonMusic.value = "暂无" + songName.value + "的资源"
                        } else {
                            songs.value = resp.data.songs
                            nonMusic.value = ""

                        }
                        // console.log(songs);


                    }, error(resp) {
                        // alert("暂无" + songName.value + "资源")
                        nonMusic.value = "暂无" + songName.value + "的资源"

                        console.log(resp)
                    }

                })
            }
        }
        // get_music_list()


        const get_music_info = (id) => {
            store.dispatch("get_a_music", {
                id: id,
                success() {
                    console.log("加载歌曲信息成功")
                    // console.log(resp);


                }, error() {
                    // console.log(resp);
                    alert("加载歌曲失败")
                }
            })
        }

        return {
            listen,
            next_random_music,
            is_phone,
            songs,
            songName,
            songIdx,
            get_music_list,
            get_music_info,
            nonMusic,
        }
    },


}


</script>

<style>
img.ranklist-user-photo {
    width: 5vh;
    border-radius: 15%;
}

td {
    font-size: x-large;
    font-weight: 600;
    font-family: 黑体;
}

/* span {
    font-size: x-large;
    font-weight: 600;

    font-family: 微软雅黑;


} */

th {
    font-size: x-large;
    font-weight: 1000;

    font-family: 微软雅黑;
}

.phone_td {
    font-size: small;
    font-weight: 600;
    font-family: 微软雅黑;
}

.phone_th {
    font-size: small;
    font-weight: 1000;

    font-family: 黑体;
}
</style>