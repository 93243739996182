import $ from 'jquery';

const ModuleMusic = {
    state: {
        mp3Url: "",
        picUrl: "",
        token: "W18jiqKwXqN1uKxV",
        name: "",
        auther: "",
        search_name: "张杰"

    },
    getters: {
    },
    mutations: {
        setMusic(state, data) {
            state.mp3Url = data.mp3Url,
                state.picUrl = data.picUrl,
                state.name = data.name,
                state.auther = data.auther
        },

        setMusicUrl(state, data) {
            state.mp3Url = data.mp3Url
        },

        setMusicInfo(state, data) {
            state.picUrl = data.picUrl,
                state.name = data.name,
                state.auther = data.auther
        },
        setSearchName(state, data) {
            state.search_name = data.search_name
        }



    },
    actions: {

        get_random_music(context, data) {


            $.ajax({
                type: "get",
                url: "https://api.vvhan.com/api/rand.music",
                data: {
                    sort: "热歌榜",
                    type: "json"

                }
                , success(resp) {
                    context.commit("setMusic", {
                        mp3Url: resp.info.mp3url,
                        picUrl: resp.info.picUrl,
                        name: resp.info.name,
                        auther: resp.info.auther
                    })
                    console.log(resp)

                    data.success();
                }, error(resp) {
                    console.log(resp);

                    data.error();

                }
            })
        },



        get_a_music(context, data) {

            $.ajax({
                type: "get",
                url: "https://v2.alapi.cn/api/music/url",
                data: {
                    id: data.id,
                    format: "json",
                    token: "W18jiqKwXqN1uKxV"
                }, success(resp) {
                    context.commit("setMusicUrl", {
                        mp3Url: resp.data.url,
                    })


                }, error(resp) {
                    console.log(resp);
                }

            })


            $.ajax({
                type: "get",
                url: "https://v2.alapi.cn/api/music/detail",
                data: {
                    id: data.id,
                    token: "W18jiqKwXqN1uKxV"
                }, success(resp) {

                    console.log(resp)
                    context.commit("setMusicInfo", {
                        picUrl: resp.data.songs[0].al.picUrl,
                        name: resp.data.songs[0].name,
                        auther: resp.data.songs[0].ar[0].name
                    })
                    // data.success(resp);


                }, error() {
                    // console.log(resp)
                    console.log("获取歌曲详细信息失败")
                }

            })


        },


        set_search_name(context, data) {
            context.commit("setSearchName", data.search_name)
        }


    },
    modules: {

    }
}

export default ModuleMusic;


