<template>
    <BlankBG v-if="screenWidth >= 600">
        <div class="row justify-content-md-center">
            <div class="col-3">
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名:</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码:</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="mb-3">
                        <label for="confirm_password" class="form-label">重复密码:</label>
                        <input v-model="confirm_password" type="password" class="form-control" id="confirm_password"
                            placeholder="请输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">注册新用户</button>
                </form>
            </div>
        </div>


    </BlankBG>
    <div v-else>
        <form @submit.prevent="register">
            <div class="mb-3">
                <label for="username" class="form-label">用户名:</label>
                <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">密码:</label>
                <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
            </div>
            <div class="mb-3">
                <label for="confirm_password" class="form-label">重复密码:</label>
                <input v-model="confirm_password" type="password" class="form-control" id="confirm_password"
                    placeholder="请输入密码">
            </div>
            <div class="error-message">{{ error_message }}</div>
            <button type="submit" class="btn btn-primary">注册新用户</button>
        </form>
    </div>
</template>
    
<script>

import BlankBG from "@/components/BlankBG.vue"
import { ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

export default {
    name: "LoginPage",
    components: {
        BlankBG,
    },
    data() {
        return {
            screenWidth: 1024,
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth
    },

    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let confirm_password = ref('');

        let error_message = ref('');


        const register = () => {
            let password1 = password.value;
            let password2 = confirm_password.value;
            let username1 = username.value

            if (password1.length != password2.length) {
                error_message.value = "两次密码不相符"
                return
            }
            if (username1.length < 2) {
                error_message.value = "用户名不能少于两个字符"
                return
            }
            if (password1.length < 6) {
                error_message.value = "密码不能少于两个字符"
                return
            }




            error_message.value = "";
            store.dispatch("register", {
                username: username1,
                password: password1,
                repeatPassword: password2,
                success() {
                    // console.log("登录成功")
                    alert("注册成功")

                    store.dispatch("getInfo", {
                        success() {
                            console.log("拉取用户信息成功"),
                                router.push({ name: "home" })
                        },
                        error() {
                            console.log("拉取用户信息失败")
                        }
                    })
                },
                error(resp) {
                    console.log(resp)
                    error_message.value = resp
                }
            })

        }




        return {
            username,
            password,
            confirm_password,
            error_message,
            register
        }
    }
}
</script>
    
<style scoped>
button {
    width: 100%;
}

div.error-message {
    color: red;
}
</style>