<template>
    <div class="container" v-if="screenWidth >= 600">
        <div class="row" style="padding-top: 3vh; opacity: 0.99;">
            <div class="col-3">
                <div style="position: sticky; top:30px;  border-radius: 0%; ">
                    <div class="left-avator-padding">
                        <div class="row">
                            <div class="col-6">
                                <div style="float: left;">
                                    <img :src="$store.state.user.avatar" style="width: 6vw; height: 6vw; ">
                                </div>
                            </div>
                            <div class="col-6">
                                <div style="text-align: center; padding-top: 0vh;">
                                    <span v-if="$store.state.user.username.length < 5"><b>{{ $store.state.user.username
                                    }}</b></span>
                                    <span v-else>
                                        <small style="font-weight: bolder;"><b>{{ $store.state.user.username
                                        }}</b></small>
                                    </span>
                                    <br>
                                    <small>粉丝：500亿</small>
                                    <br>
                                    <!-- <button type="button" class="btn btn-outline-dark btn-sm"
                                        @click="downFile($store.state.user.avatar)">下载</button> -->
                                    <button type="button" class="btn btn-outline-dark btn-sm"
                                        @click="downFile('https://img.alowlife.com/i/3/2024/03/15/wallhaven-438w60-3.webp')">下载</button>
                                    <!-- <a :href="$store.state.user.avatar" download>下载</a> -->
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                    <div style="padding-top: 1px;"></div>

                    <div class="left-proverb-padding">
                        个人简介：<br>
                        {{ $store.state.user.proverb }}
                    </div>
                    <!-- <hr class="hr-wavy"> -->

                    <!-- <div class="left-article-padding">
                        热门文章：
                        <div v-for="articleItem in articleHot" :key="articleItem.id">
                            <router-link style=" text-decoration: none; color: black;"
                                :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }">
                                <kbd style="color: azure; font-size: small;">{{ articleItem.article.tittle
                                }}
                                </kbd>
                                <span style="font-size: small;">
                                    ——热度<mark>{{
                                        articleItem.article.viewCount
                                    }}</mark>
                                </span>
                            </router-link>
                        </div>

                    </div> -->
                    <div style="padding-top: 1px;"></div>


                    <div class="left-article-padding">
                        我的标签:
                        <br>
                        <span v-for=" label  in  labelList " :key="label.id">
                            <router-link style=" text-decoration: none; color: black;"
                                :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 1 } }"><kbd
                                    style="color: azure; font-size: small; ">{{ label.labelName }}
                                </kbd>*{{ label.labelCount
                                }}</router-link>&nbsp;
                        </span>
                    </div>
                    <div style="padding-top: 1px;"></div>


                    <div class="left-comment-padding">
                        最近评论：
                        <div v-for=" comment  in  commentList.commentList " :key="comment.id">
                            <a style="font-size: 10px; text-decoration: none; color: black;">
                                {{ timestampToTime(Date.parse(new
                                    Date(comment.comment.createTime).toString())) }}
                            </a>
                            <br>
                            <a style="font-size: 12px;">{{ comment.comment.comment }}
                            </a>
                            &nbsp;
                            <a @click="del_comment(comment.comment.commentId)"
                                v-if="comment.comment.authorId == $store.state.user.id"
                                style="color:#3c0ac5; font-size: 12px; text-decoration: underline;">删除</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <nav class="navbar navbar-light bg-light" style="border: 1px solid skyblue">
                    <div class="container-fluid">
                        <a class="navbar-brand">发布文章：{{ articleCount }} 篇</a>
                    </div>
                </nav>


                <div style="">
                    <div class="list-group">
                        <div v-for=" articleItem  in  articleList " :key="articleItem.id">
                            <div style="padding-top: 1px;"></div>

                            <a href="#" @click="show_article(articleItem.article.articleId)"
                                class="list-group-item list-group-item-action " style="border: 1px solid skyblue;">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                                    <small>{{ new Date(articleItem.article.createTime).toLocaleDateString() }}</small>
                                </div>
                                <p class="mb-1">简介：{{ articleItem.article.brief }}</p>

                                <span>
                                    <button type="button" @click="show_article(articleItem.article.articleId)"
                                        class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>是否置顶：
                                    <small v-if="articleItem.article.top"><kbd>是</kbd></small>
                                    <small v-else><kbd>否</kbd></small>

                                </span>


                                <small style="margin: 10px;">文章热度：<kbd>{{ articleItem.article.viewCount }}</kbd></small>

                            </a>
                        </div>

                    </div>
                    <br>
                    <nav aria-label="Page navigation example">
                        <button class="btn btn-primary " style="float:right" @click="gotoPageNum()">跳转</button>
                        <input type="number" class="form-control" style="width: 120px; float:right"
                            :placeholder="'共有' + totalPage + '页'" v-model="toPageNum">
                        &nbsp;&nbsp;&nbsp;
                        <ul class="pagination  pagination-sm " style="float:right">
                            <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                            </li>
                            <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                                v-for="  page  in  showPage " :key="page.number">
                                <a class="page-link" href="#">{{ page.number }}</a>
                            </li>

                            <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

    </div>

    <!-- +++++++++++++++++++++++++++++++++++++++++++++手机端 -->
    <div v-else>

        <div style="padding: 1vw;  border-radius: 0%; opacity: 0.99;">
            <div class="">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <div style="float: left;">
                            <img :src="$store.state.user.avatar" style="width: 90px; height: 90px; ">
                        </div>
                    </div>
                    <div class="col-6">
                        <div style="text-align: center; padding-top: 0vh;">
                            <span v-if="$store.state.user.username.length < 5"><b>{{ $store.state.user.username
                            }}</b></span>
                            <span v-else>
                                <small style="font-weight: bolder;"><b>{{ $store.state.user.username
                                }}</b></small>
                            </span>
                            <br>
                            <small>粉丝：500</small>
                            <br>
                            <button type="button" class="btn btn-outline-dark btn-sm">关注</button>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <hr class="hr-wavy">

            <div class="left-proverb-padding" style="border: none; height: 120px;">
                个人简介：<br>
                {{ $store.state.user.proverb }}
            </div>
            <hr class="hr-wavy">

            <div class="left-article-padding" style="border: none; height: 150px;">
                热门文章：
                <div v-for=" articleItem  in  articleHot " :key="articleItem.id">
                    <router-link style=" text-decoration: none; color: black;"
                        :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }">
                        <kbd style="color: azure; font-size: small;">{{ articleItem.article.tittle
                        }}
                        </kbd>
                        <span style="font-size: small;">
                            ——热度<mark>{{
                                articleItem.article.viewCount
                            }}</mark>
                        </span>
                    </router-link>
                </div>

            </div>
            <hr class="hr-wavy">

            <div class="left-article-padding" style="border: none; height: 102px;">
                我的标签:
                <br>
                <span v-for=" label  in  labelList " :key="label.id">
                    <router-link style=" text-decoration: none; color: black;"
                        :to="{ name: 'label_article_list', query: { labelName: label.labelName, myArticle: 1 } }"><kbd
                            style="color: azure; font-size: small; ">{{ label.labelName }}
                        </kbd>*{{ label.labelCount
                        }}</router-link>&nbsp;
                </span>
            </div>
            <hr class="hr-wavy">

            <div class="left-comment-padding">
                最近评论：
                <div v-for=" comment  in  commentList.commentList " :key="comment.id">
                    <!-- <router-link style="font-size: 8px; text-decoration: none; color: black;"
                            :to="{ name: 'article', query: { articleId: comment.comment.answerArticleId } }">
                            {{ timestampToTime(Date.parse(new
                                Date(comment.comment.createTime).toString())) }} </router-link><br> -->
                    <a style="font-size: 10px; text-decoration: none; color: black;">
                        {{ timestampToTime(Date.parse(new
                            Date(comment.comment.createTime).toString())) }}
                    </a>
                    <br>
                    <a style="font-size: 12px;">{{ comment.comment.comment }}
                    </a>
                    &nbsp;
                    <a @click="del_comment(comment.comment.commentId)"
                        v-if="comment.comment.authorId == $store.state.user.id"
                        style="color:#3c0ac5; font-size: 12px; text-decoration: underline;">删除</a>
                </div>
            </div>
        </div>
        <br>
        <div style="padding:1vw; ">
            <nav class="navbar navbar-light ">
                <div class="container-fluid">
                    <a class="navbar-brand">发布文章：{{ articleCount }} 篇</a>
                </div>
            </nav>
        </div>
        <div style="width: 100%; padding:1vw; opacity:0.98;">
            <div class="list-group ">
                <div v-for=" articleItem  in  articleList " :key="articleItem.id">
                    <a href="#" @click="show_article(articleItem.article.articleId)"
                        class="list-group-item list-group-item-action ">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ articleItem.article.tittle }}</h5>
                            <small>{{ new Date(articleItem.article.createTime).toLocaleDateString() }}</small>
                        </div>
                        <p class="mb-1">简介：{{ articleItem.article.brief }}</p>

                        <span>
                            <button type="button" @click="show_article(articleItem.article.articleId)"
                                class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button>是否置顶：
                            <small v-if="articleItem.article.top"><kbd>是</kbd></small>
                            <small v-else><kbd>否</kbd></small>

                        </span>


                        <small style="margin: 10px;">文章热度：<kbd>{{ articleItem.article.viewCount }}</kbd></small>

                    </a>
                </div>

            </div>
            <br>
            <nav aria-label="Page navigation example">
                <button class="btn btn-primary " style="float:right" @click="gotoPageNum()">跳转</button>
                <input type="number" class="form-control" style="width: 120px; float:right"
                    :placeholder="'共有' + totalPage + '页'" v-model="toPageNum">
                &nbsp;&nbsp;&nbsp;
                <ul class="pagination  pagination-sm " style="float:right">
                    <li class="page-item" @click="click_page(-2, articleList)"><a class="page-link" href="#">上一页</a>
                    </li>
                    <li @click="click_page(page.number, articleList)" :class="'page-item ' + page.is_active"
                        v-for="  page  in  showPage " :key="page.number">
                        <a class="page-link" href="#">{{ page.number }}</a>
                    </li>

                    <li class="page-item" @click="click_page(-1, articleList)"><a class="page-link" href="#">下一页</a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</template>
    
<script>

// import BlankBG from "@/components/BlankBG.vue"
import { useStore } from 'vuex';
import { ref } from 'vue';
import $ from 'jquery'
import router from '@/router';
export default {
    name: "UserProfile",
    components: {
        // BlankBG,
    },
    data() {
        return {
            screenWidth: 1024,
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth
    },

    methods: {
        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },


        downFile(fileUrl) {
            const Url = fileUrl
            console.log(Url)
            window.open(Url)

            // const link = document.createElement('a')
            // link.href = fileUrl
            // link.download = 'avatar.webp'
            // link.click()


        }
        ,
        downloadAvatar(fileUrl) {
            if (this.cloudDiskIds.length == 0) {
                alert("请选择要下载的文件！")
                return
            }
            //   var _this = this;
            $.ajax({
                type: 'GET',
                url: fileUrl,
                data: this.formData,
                contentType: false,
                processData: false,
                dataType: 'json',
                timeout: 8000,
                success: (result) => {
                    if (result.success) {
                        alert("下载成功")
                    } else {
                        alert(result.msg)
                    }
                },
                error: (err) => {
                    console.log("err: " + JSON.stringify(err))
                }
            })
        }

    },
    setup() {
        const store = useStore();

        const articleList = ref([])
        const articleHot = ref([])
        const labelList = ref([])
        // const PAGE = 6;
        let currentPage = 1;
        const totalPage = ref("");
        totalPage.value = 1
        let showPage = ref([]);


        const timestampToTime = (timestamp) => {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }


        const show_article = (articleId) => {
            router.push({
                path: "/article",
                query: {
                    authorId: store.state.user.id,
                    articleId: articleId
                }
            })
        }


        const update_pages = () => {
            // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
            let max_pages = totalPage.value;
            // console.log("最大页数" + max_pages)

            let new_page = [];
            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }

            showPage.value = new_page;

        }

        const click_page = (page) => {
            if (page === -1) {
                page = currentPage + 1;

            } else if (page === -2) {
                page = currentPage - 1;
            }
            let max_pages = totalPage.value;
            if (page >= 1 && page <= max_pages) {
                console.log("获取页面：" + page)
                pull_page(page);
            }
        }

        const articleCount = ref("")

        const pull_page = (page) => {
            currentPage = page;

            $.ajax({
                //url格式：article/list/页码/用户id/是否需要草稿/
                url: store.state.BaseUrl + "/api/article/common/hot/" + page + "/" + store.state.user.id,
                type: "get",
                // headers: {
                //     Authorization: "Bearer " + store.state.user.token,
                // },

                success(resp) {

                    articleList.value = resp.content.articleList;
                    articleCount.value = resp.content.totalCount

                    totalPage.value = resp.content.totalPage;
                    update_pages();


                },
            })
        }

        //获得热门文章
        $.ajax({
            //热门文章
            url: store.state.BaseUrl + "/api/article/common/hot/" + 1 + "/" + store.state.user.id,
            type: "get",
            // headers: {
            //     Authorization: "Bearer " + store.state.user.token,
            // },

            success(resp) {

                articleHot.value = resp.content.articleList;
            },

        })

        //获得标签列表
        $.ajax({
            url: store.state.BaseUrl + "/api/label/common/list/" + store.state.user.id,
            type: "get",
            // headers: {
            //     Authorization: "Bearer " + store.state.user.token,
            // },

            success(resp) {

                labelList.value = resp.content;
                // console.log(labelList)

                // alert(labelList.value)
            }, error() {

            }

        })

        //获得评论列表
        const commentList = ref([])
        const get_comment_list = () => {
            $.ajax({
                url: store.state.BaseUrl + "/api/comment/author/" + store.state.user.id + "/" + 1,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        // console.log(resp)
                        commentList.value = resp.content
                        // console.log(commentList.value)
                    } else {
                        // console.log(resp)
                    }
                },
                // error(resp) {
                //     console.log(resp)
                // }
            })
        }

        const del_comment = (commentId) => {
            $.ajax({
                url: store.state.BaseUrl + "/api/comment/" + commentId,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.code == 1) {
                        // console.log(resp)
                        get_comment_list()

                    } else {
                        console.log(resp)
                    }
                }

            })
        }



        const toPageNum = ref("");

        const gotoPageNum = () => {
            if (toPageNum.value <= 0 || toPageNum.value > totalPage.value) {
                toPageNum.value = 1
                alert("页码超出范围")
                return
            }
            pull_page(toPageNum.value)

        }

        get_comment_list()
        pull_page(currentPage)

        return {
            gotoPageNum,
            toPageNum,
            articleList,
            labelList,
            click_page,
            showPage,
            show_article,
            articleHot,
            commentList,
            timestampToTime,
            del_comment,
            articleCount,
            totalPage
        }





    }



}


</script>
    
<style scoped>
.left-avator-padding {
    /* background-color: white; */
    padding-top: 2vw;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: white;
    border: 1px solid skyblue;

    border-radius: 0%;
}

.left-proverb-padding {
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: white;
    padding-top: 1vw;

    overflow-y: auto;
    font-size: small;
    height: 10vh;
    border: 1px solid skyblue;

    /* overflow-y: scroll; */
}

.left-article-padding {
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: white;
    padding-top: 1vw;

    font-size: small;

    height: 12vh;
    overflow-y: auto;
    border: 1px solid skyblue;
}

.left-comment-padding {
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: white;
    padding-top: 1vw;

    font-size: small;
    border: 1px solid skyblue;
    height: 15vh;
    overflow-y: auto;
}


.hr-wavy {
    border: 0;
    padding: 3px;
    background: repeating-linear-gradient(135deg, black 0px, #0358f7 1px, transparent 1px, transparent 6px);
}

div::-webkit-scrollbar {
    width: 8px;
    height: 1px;
}

div::-webkit-scrollbar-thumb {
    background: grey;
}

div::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px white;
}
</style>