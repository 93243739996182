<template>
    <div>
        <BlankBG style="opacity: 0.99;">
            <p v-if="myArticle == 0" style="color:white">关于"{{ labelName }}"的文章有{{ totalCount }}篇：</p>
            <p v-if="myArticle == 1" style="color:white">关于"{{ labelName }}"的文章有{{ totalCount }}篇：</p>


            <div class="list-group">
                <div v-for="articleItem in articleList" :key="articleItem.id">
                    <div class="list-group-item list-group-item-action " style="border: 1px solid rgb(255, 234, 151);">
                        <router-link style="color: black; text-decoration: none;"
                            :to="{ name: 'article', query: { articleId: articleItem.article.articleId, key: randomString(10), authorId: articleItem.article.authorId } }">
                            <div class="d-flex w-100 justify-content-between">
                                <p class="mb-1" style="font-weight: bolder;">{{ articleItem.article.tittle }}</p>
                                <!-- <small>{{ new Date(article.createTime).toLocaleDateString() }}</small> -->
                                <small>发布时间： {{ timestampToTime(Date.parse(new
                                    Date(articleItem.article.createTime).toString())) }}</small>

                            </div>

                            <p class="mb-1" style="font-size: small;">简介：{{ articleItem.article.brief }}</p>
                        </router-link>

                        <small>
                            <!-- 作者：<kbd>{{ articleItem.author }}</kbd> &nbsp;  -->
                            <router-link style="text-decoration: none;  color: black;"
                                :to="{ name: 'friend', query: { friendId: articleItem.article.authorId } }">
                                <kbd>{{ articleItem.author }} </kbd>

                            </router-link>

                            热度：<kbd>{{ articleItem.article.viewCount
                            }}</kbd></small>

                        <span v-for="label in articleItem.labelList" :key="label.id" style="float: right;">
                            <p style="float: right; margin-left: 3px;">
                                <!-- <kbd>{{ label }}</kbd> -->
                                <mark @click="show_label_article(label, 0)">{{ label }}</mark>

                            </p>

                        </span>
                        <!-- <p style="float: right;">标签：</p> -->


                        <!-- <small>文章热度：{{ article.viewCount }}</small> -->
                    </div>
                </div>

            </div>
            <nav aria-label="Page navigation example">
                <ul class="pagination  pagination-sm " style="float:right">
                    <li class="page-item" @click="click_page(-2)"><a class="page-link" href="#">上一页</a></li>
                    <li @click="click_page(page.number)" :class="'page-item ' + page.is_active" v-for=" page in showPage"
                        :key="page.number">
                        <a class="page-link" href="#">{{ page.number }}</a>
                    </li>

                    <li class="page-item" @click="click_page(-1)"><a class="page-link" href="#">下一页</a></li>
                </ul>
            </nav>

        </BlankBG>
    </div>
</template>

<script>

import BlankBG from "@/components/BlankBG.vue"
// import { useStore } from "vuex";
import store from "@/store"
import $ from "jquery"
// import ArticleBar from "@/components/ArticleBar.vue"
// import { ref } from "vue";
import router from "@/router";
// import { inject } from "vue";

export default {
    name: "LabelArticleListView",
    components: {
        BlankBG,
        // ArticleBar,
    },
    data() {

        return {
            articleList: [],
            showPage: "",
            labelName: "",

            currentPage: 1,
            totalPage: 1,
            totalCount: 0,

            myArticle: 0,

        }

    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$route.query.labelName || this.$route.query.labelName) {//需要监听的参数
                    this.labelName = this.$route.query.labelName
                    // console.log("????????????" + this.labelName)
                    this.myArticle = this.$route.query.myArticle
                    this.pull_page(this.currentPage)

                }
            }
        },

    },
    methods: {

        show_label_article(labelName, myArticle) {

            router.push({
                path: "/label-article-list",
                query: {
                    authorId: store.state.user.id,
                    myArticle: myArticle,

                    labelName: labelName,

                }
            })

        },

        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },

        update_pages() {
            // let max_pages = parseInt(Math.ceil(totalPage / PAGE));
            let max_pages = this.totalPage;
            // console.log("最大页数" + max_pages)

            let new_page = [];
            for (let i = this.currentPage - 2; i < this.currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === this.currentPage ? "active" : "",
                    })
                }
            }

            this.showPage = new_page;

        },

        click_page(page) {
            if (page === -1) {
                page = this.currentPage + 1;

            } else if (page === -2) {
                page = this.currentPage - 1;
            }
            let max_pages = this.totalPage;
            if (page >= 1 && page <= max_pages) {
                // console.log("获取页面：" + page)
                this.pull_page(page);
            }
        },

        pull_page(page) {
            this.currentPage = page;
            const that = this
            that.articleList = []
            that.totalCount = 0;

            // console.log("----------------" + this.myArticle)

            if (this.myArticle == 0) {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/common/list/byLabel/" + that.labelName + "/false/" + 0 + "/" + that.currentPage,
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },

                    success(resp) {

                        if (resp.code == 1) {
                            that.articleList = resp.content.articleList;
                            // console.log(resp.content.articleList);
                            that.totalPage = resp.content.totalPage;
                            that.totalCount = resp.content.totalCount;
                            that.update_pages();
                            // console.log(that.articleList)
                            // console.log("拉取文章列表成功")
                        }



                    },

                })
            } else {
                $.ajax({
                    url: store.state.BaseUrl + "/api/article/common/list/byLabel/" + that.labelName + "/false/" + store.state.user.id + "/" + that.currentPage,
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },

                    success(resp) {

                        if (resp.code == 1) {
                            that.articleList = resp.content.articleList;
                            // console.log(resp.content.articleList);
                            that.totalPage = resp.content.totalPage;
                            that.totalCount = resp.content.totalCount;
                            that.update_pages();
                            // console.log(that.articleList)
                            // console.log("拉取文章列表成功")
                        }



                    },

                })
            }




        },
        timestampToTime(timestamp) {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }



    },

    mounted() {

    },

    setup() {

    }
}


</script>

<style scoped></style>