import { createStore } from 'vuex'
import ModuleUser from '@/store/user'
import ModuleFriend from '@/store/friend'
import ModuleMusic from '@/store/music'



export default createStore({
  state: {
    //上线
    BaseUrl: "https://alowlife.com",
    // BaseUrl: "https://irun.asia",


    //测试
    // BaseUrl: "http://localhost:10999",
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: ModuleUser,
    friend: ModuleFriend,
    music: ModuleMusic,
    // post: ModulePost
  }
})
