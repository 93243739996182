import $ from 'jquery'
import store from '.';

export default ({
    state: {
        id: "",
        username: "",
        avatar: "",
        proverb: "",
        email: "",
        phoneNumber: "",
        bg:"https://img.alowlife.com/i/3/2024/03/15/wallhaven-438w60-3.webp",
    },
    getters: {
    },
    mutations: {
        updateFriendBg(state, bgUrl){
            state.bg = bgUrl;
        },

        updateFriend(state, user) {
            state.id = user.userId;
            state.username = user.username;
            state.avatar = user.avatar;
            state.proverb = user.proverb;
            state.email = user.email;
            state.phoneNumber = user.phoneNumber;
            state.bg = user.bg
        },
        clearFriend(state) {
            state.id = "";
            state.username = "";
            state.avatar = "";
            state.proverb = "";
            state.email = "";
            state.phoneNumber = "";
            state.bg = "https://img.alowlife.com/i/3/2024/03/15/wallhaven-438w60-3.webp"
        }

    },
    actions: {


        getFriendInfo(context, data) {
            $.ajax({
                url: store.state.BaseUrl + "/api/user/common/" + data.friendId,
                type: "GET",
                // headers: {
                //     Authorization: "Bearer " + store.state.user.token,
                // },
                success(resp) {
                    if (resp.code == 1) {
                        console.log(resp.content)
                        context.commit("updateFriend", {
                            ...resp.content,
                        })
                        console.log("拉取文章作者：" + resp);
                        data.success();
                    } else {
                        data.error(resp.content);
                        console.log(resp)
                        context.commit("clearFriend")
                        // alert(resp.content)

                    }
                },
                error() {
                    // console.log(resp)
                    context.commit("clearFriend")
                    data.error();
                }
            })
        },

        // clearFriendInfo(context, data){

        // }



    },
    modules: {

    }
})
