<template>
    <!-- <BlankG style="opacity: 0.99;" v-if="screenWidth >= 600"> -->
    <BlankG style="opacity: 0.99;">

        <div class="row" style="padding-top: 2vh;">
            <div class="col-1"></div>
            <div class="col-10">
                <nav class="navbar navbar-light bg-light" style="border: 1px solid rgb(255, 234, 151);">
                    <div class="container-fluid">

                        <a class="navbar-brand">用户管理：共有 {{ totalCount }} 位用户</a>
                        <!-- <div class="d-flex">
                            <input v-model="" class="form-control me-2" type="search" placeholder="查找文章标题"
                                aria-label="Search">
                            <button @click="" class="btn btn-outline-success" type="button">Search</button>
                        </div> -->
                    </div>
                </nav>
                <br>

                <table class="table table-striped table-light table-hover">
                    <thead>
                        <tr>
                            <th scope="col">序号</th>
                            <th scope="col">头像</th>
                            <th scope="col">用户名</th>
                            <th scope="col">简介</th>
                            <th scope="col">注册时间</th>
                            <th scope="col">状态</th>
                            <th scope="col">操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="userItem in userList" :key="userItem.id">
                            <th scope="row">{{ userItem.userId }}</th>
                            <td><img :src="userItem.avatar" style="width: 25px;"></td>
                            <td>
                                <!-- {{ userItem.username }} -->
                                <router-link style="text-decoration: none; color: black;"
                                    :to="{ name: 'friend', query: { friendId: userItem.userId } }">
                                    <kbd>{{ userItem.username }} </kbd>

                                </router-link>

                            </td>
                            <td>

                                <a style="color: black;" data-bs-toggle="collapse" :href="'#showProverb' + userItem.userId"
                                    role="button" aria-expanded="false" aria-controls="multiCollapseExample1">查看</a>
                                <div style="position: fixed;" class="collapse multi-collapse"
                                    :id="'showProverb' + userItem.userId">
                                    <div class="card card-body">
                                        {{ userItem.proverb }}
                                    </div>
                                </div>
                            </td>
                            <td>{{ timestampToTime(Date.parse(new
                                Date(userItem.createTime).toString())) }}
                            </td>
                            <td>
                                <text style="color: green;" v-if="userItem.valid">
                                    正常
                                </text>
                                <text style="color: red;" v-else>
                                    封禁
                                </text>
                            </td>

                            <td>
                                <span>
                                    <button type="button" @click="selectUser(userItem)" data-bs-toggle="modal"
                                        data-bs-target="#modifyUserProfile" class="btn btn-sm btn-warning"
                                        style="; margin-left: 3px;">编辑</button>
                                    <button v-if="userItem.valid" type="button"
                                        @click="confirm_delete_user(userItem.userId, 'ban')" class="btn btn-sm btn-danger"
                                        style=" margin-left: 3px;">封禁</button>
                                    <button v-else type="button" @click="confirm_delete_user(userItem.userId, 'free')"
                                        class="btn btn-sm btn-success" style=" margin-left: 3px;">解封</button>
                                    <button type="button" @click="confirm_delete_user(userItem.userId, 'del')"
                                        class="btn btn-sm btn-dark" style=" margin-left: 3px;">抹除</button>


                                    <!-- 
                                <button v-if="articleItem.article.draft" type="button"
                                    @click="public_article(articleItem.article.articleId)" class="btn btn-sm btn-success"
                                    style="float: right; margin-left: 3px;">发布</button>
                                <button v-else type="button" @click="draft_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-secondary" style="float: right; margin-left: 3px;">草稿</button>
                                <button type="button" @click="show_article(articleItem.article.articleId)"
                                    class="btn btn-sm btn-info" style="float: right; margin-left: 3px;">查看</button> -->
                                </span>

                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>
                <nav aria-label="Page navigation example">
                    <ul class="pagination  pagination-sm   pagination-sm" style="float:right">
                        <li class="page-item" @click="click_page(-2, userList)"><a class="page-link" href="#">上一页</a>
                        </li>
                        <li @click="click_page(page.number, userList)" :class="'page-item ' + page.is_active"
                            v-for=" page in showPage" :key="page.number">
                            <a class="page-link" href="#">{{ page.number }}</a>
                        </li>
                        <li class="page-item" @click="click_page(-1, userList)"><a class="page-link" href="#">下一页</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </BlankG>

    <div class="modal fade" id="modifyUserProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">修改用户信息</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ModifyUserProfile :user="user" />
                </div>
                <div class="modal-footer">
                    <button type="button" @click="pull_page(1)" class="btn btn-secondary"
                        data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import BlankG from '@/components/BlankBG.vue';
import ModifyUserProfile from '@/components/ModifyUserProfile.vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import { ref } from 'vue';
// import router from "@/router";
export default {
    components: {
        BlankG,
        ModifyUserProfile
    },
    data() {
        return {
            screenWidth: 1024,
            user: "",
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth
    },

    methods: {
        selectUser(user) {
            this.user = user
        }
    },

    setup() {
        const store = useStore();

        const userList = ref([])
        const totalCount = ref([])
        // const PAGE = 6;
        let currentPage = 1;
        let totalPage = 1;
        let showPage = ref([]);




        const confirm_delete_user = (userId, type) => {
            let flag;

            if (type == "ban") {

                delete_user(userId, "ban");

            } else if (type == "free") {

                delete_user(userId, "ban");

            } else if (type == "del") {
                flag = confirm("是否确认彻底删除用户")
                if (flag == true) {
                    delete_user(userId, "del");
                }
            }



        }

        const delete_user = (userId, type) => {

            if (userId == store.state.user.id) {
                alert("不能封禁自己")
                return;
            }

            $.ajax({
                url: store.state.BaseUrl + "/api/admin/users/" + userId + "/" + type,
                type: "delete",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },

                success(resp) {
                    if (resp.code == 1) {
                        alert("操作成功")
                        pull_page(1)
                    } else {
                        alert("操作失败")
                    }
                },
                error() {
                    alert("操作失败")
                },
            })
        }


        const update_pages = () => {
            let max_pages = totalPage;
            console.log("最大页数" + max_pages)
            let new_page = [];
            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_page.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }

            showPage.value = new_page;

        }

        const click_page = (page) => {
            if (page === -1) {
                page = currentPage + 1;
            } else if (page === -2) {
                page = currentPage - 1;
            }
            let max_pages = totalPage;
            if (page >= 1 && page <= max_pages) {
                console.log("获取页面：" + page)
                pull_page(page);
            }
        }

        const pull_page = (page) => {
            currentPage = page;
            $.ajax({
                url: store.state.BaseUrl + "/api/admin/users/list/" + currentPage + "/" + "all",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {

                    userList.value = resp.content.userList;
                    console.log(resp);
                    totalPage = resp.content.totalPage;
                    totalCount.value = resp.content.totalCount

                    update_pages();
                    console.log("拉取 " + userList.value + " 文章列表成功")


                },

            })

        }

        const timestampToTime = (timestamp) => {
            timestamp = timestamp ? timestamp : null;
            let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }

        pull_page(currentPage)
        return {
            userList,
            click_page,
            showPage,
            pull_page,
            // delete_user,
            confirm_delete_user,
            timestampToTime,
            totalCount
        }





    }



};

</script>

<style scoped>
.article-list-padding {
    height: 50vh;
    width: 55vw;
    position: fixed;
    overflow-y: scroll;
}
</style>