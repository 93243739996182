<template>

  <div @mousemove="handleMouseMove" v-if="$store.state.user.bg == null || $store.state.user.id == 0">

    <!-- <img v-if="screenWidth <= 600" src="https://img.alowlife.com/i/2/2023/12/01/sihqe2-2.webp"
      class="img-phone-background">
    <img v-else style="z-index: -10;" src="https://img.alowlife.com/i/2/2023/12/01/sihqe2-2.webp"
      class="img-window-background"> -->
    <img v-if="screenWidth <= 600" :src="$store.state.friend.bg" class="img-phone-background">
    <img v-else style="z-index: -10;" :src="$store.state.friend.bg" class="img-window-background">

    <div
      style="z-index: -9;  opacity: 1; min-height: 100vh; background-color: rgb(0,0,0,0.2);  font-family: 微软雅黑; font-weight:800;">
      <nav>
        <NavBar :mouseX="mouseXX" :mouseY="mouseYY" v-if="isRouterAlive" />
      </nav>
      <router-view v-if="isRouterAlive" />
      <br>

    </div>

  </div>
  <div  @mousemove="handleMouseMove" v-else class="is_login-div">
    <div>
      <img v-if="screenWidth <= 600" :src="$store.state.user.bg" class="img-phone-background">
      <img v-else :src="$store.state.user.bg" class="img-window-background">
    </div>

    <div
      style="z-index: -9;  opacity: 1; min-height: 100vh; background-color: rgb(0,0,0,0.2);  font-family: 微软雅黑; font-weight:800;'">
      <nav>
        <NavBar :mouseX="mouseXX" :mouseY="mouseYY" v-if="isRouterAlive" />
      </nav>
      <router-view  v-if="isRouterAlive" />
      <br>
    </div>

  </div>
  <div v-if="screenWidth >= 800" class="footer">

    <span><kbd style="font-size: smaller; color:red; ">© 2023</kbd></span>
    &nbsp;
    <span>
      <a href="https://zuoguai.github.io/AboutMe/" target="_blank" style="color: white; text-decoration:none;">
        <kbd style="font-size: smaller;  color:skyblue;">作怪</kbd>
      </a>
    </span>
    &nbsp;
    <span>
      <!-- <mark style="font-size: smaller; color:black; border-radius: 4%;"> -->
      <kbd style="font-size: smaller; color:greenyellow;">zuoguai2023@foxmail.com</kbd>
      <!-- </mark> -->
    </span>
    &nbsp;
    <span>
      <!-- <mark style="font-size: smaller; color:black; border-radius: 4%;"> -->
      <kbd style="font-size: smaller;color:pink; ">
        运行：{{ run_day }}
      </kbd>
      <!-- </mark> -->
    </span>
    <!-- <span>作者：</span>
    <span>作者：</span> -->

  </div>
</template>

<script>

import NavBar from "@/components/NavBar.vue"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

import { ref, nextTick, provide } from "vue"
import store from "./store"

// import 'bootstrap';
import $ from 'jquery'

export default {
  name: "App",
  components: {
    NavBar
  },
  data() {
        return {
            // myLabels: []
            mouseXX:'100',
            mouseYY:'100'
        }
    },
    methods: {

        alert_msg(msg) {
            alert(msg)
        },

        handleMouseMove(event) {
      this.mouseXX = parseInt(event.clientX * Math.random() ) % 101;
      this.mouseYY = event.clientY;
    }},
  setup() {
    // 局部组件刷新
    const screenWidth = ref("")
    screenWidth.value = 1024;
    screenWidth.value = window.innerWidth
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);

    let now_time = ref("")
    let birth_day = 1702224000
    let run_day = ref("")


    setInterval(() => {
      now_time.value = Math.round(new Date().getTime() / 1000)
      run_day.value = cal_length((now_time.value - birth_day) * 1000)
      // console.log(run_day)
      // end_time.value = new Date(new Date().getTime() + timeLength.value * 60 * 1000).toLocaleTimeString();
    }, 10)

    // const loadAuthorInfo=()=> {
    //         store.dispatch("getFriendInfo", {
    //             friendId: 1,
    //         })

    //     }
    // loadAuthorInfo()

    // store.commit("updateFriendBg",)

    const cal_length = (len) => {

      let res = ""
      let seconds = (len / 1000)

      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      let days = Math.floor(hours / 24)

      minutes = minutes - hours * 60
      seconds = seconds - hours * 60 * 60 - minutes * 60
      hours = hours - days * 24

      if (days >= 1) {
        res += days + " 天 "
      }

      if (hours >= 1) {
        res += hours + " 小时 "
      }
      if (minutes >= 1) {
        res += " " + minutes + " 分"
      }

      res += " " + seconds.toFixed(0) + " 秒"

      if (res == "") {
        res = " 0 秒"
      }
      return res

    }

    const loadBingBg = () => {
      let bing = "https://v2.alapi.cn/api/bing"
      let acg = "https://v2.alapi.cn/api/acg"
      let flag = 1 + Math.floor(Math.random() * 99)
      let pictureUrl = bing
      console.log(flag)
      if (flag > 50) {        //获取bing的壁纸
        pictureUrl = bing
      } else if (flag > 20) {    //获取作怪的壁纸
        store.dispatch("getFriendInfo", {
          friendId: 1,
        })
        return
      } else if (flag > 5) {
        store.commit("updateFriendBg", "https://img.alowlife.com/i/2/2023/12/01/sihqe2-2.webp")
        return
      } else {                //获取漫画壁纸
        pictureUrl = acg
      }
      $.ajax({
        type: "post",

        url: pictureUrl,
        data: {
          token: store.state.music.token,
          format: "json"
        }
        , success(resp) {
          store.commit("updateFriendBg", resp.data.url)
          console.log(resp.data)
        }
      })
    }

    loadBingBg()

    return {
      isRouterAlive,
      screenWidth,
      run_day,
    };
  },

}


</script>

<style scoped>
.footer {
  text-align: center;
  /* font-family: 微软雅黑; */
  /* font-weight: 10; */
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(255, 255, 255);

}

.footer:hover {
  /* background-color: rgba(255, 255, 255, 0.1); */
}

nav a.router-link-exact-active {
  color: #42b983;
}

.is_login-div {
  /* background-color: #f7f8ec; */
  /* position: relative; */
}

.img-window-background {
  z-index: -10;
  position: fixed;
  width: 100%;
  height: auto;
  /* background-repeat: repeat-y; */
  overflow: hidden;
}

.img-phone-background {
  z-index: -10;
  position: fixed;
  width: auto;
  height: 100%;
  background: repeat;
  overflow: hidden;
}
</style>
