<template>
    <SpaceWar>



        <!-- timer
            <div class="card">
                <div class="card-body">
                    timer
                    
                    <button class="btn btn-primary" @click="start_func">开始</button>
                    当前时间：{{  now_time }}
                    
                    <text >开始时间：{{ start }}</text>
                    <button class="btn btn-primary" @click="end_func">结束</button>
                    <br>
                    <text v-for="end in end_time" :key="end.id">{{ end.toLocaleTimeString() }}，持续时间{{ cal_length(end.getTime() - start_time.getTime())}}<br></text>

                    <text >持续时间{{ cal_length(new Date().getTime() - start_time.getTime())}}<br></text>

                </div>
            </div> -->



        <div class="row  opacity-100" style="min-height: 90vh; ">
            <div class="col-5 ">
                <div class="card opacity-100 my-div-body">
                    <div class="card-body ">
                        <p class="text-warning " style="font-size:larger;font-weight: 900;">计时器</p>
                        <hr>


                        <div class="" style="display: flex; justify-content: space-between; ">
                            <div class="left">
                                <span class="form-label" style="font-size: 3vh;"><kbd
                                        style="font-family: 微软雅黑;">当前时间：</kbd></span>
                            </div>
                            <div class="right"><span class="text-danger" style="font-size: 3vh;">&nbsp;{{ ' ' + now_time
                                    }}</span></div>
                        </div>

                        <div class="" style="display: flex; justify-content: space-between;">
                            <div class="left">
                                <span class="form-labe" style="font-size: 3vh;"><kbd
                                        style="font-family: 微软雅黑;">开始时间：</kbd></span>

                            </div>
                            <div v-if="!is_start" class="right"><span class="text-info " style="font-size: 3vh;">{{ ' '
                                + now_time }}</span></div>
                            <div v-else class="right"><span class="text-info " style="font-size: 3vh;">{{ ' ' + start
                                    }}</span></div>
                        </div>

                        <hr>

                        <textarea v-model="content" class="form-control" id="Textarea" placeholder="计时项目..."
                            rows="3"></textarea>

                        <p v-if="is_start" style="font-size: 70px; font-weight:900; text-align: center;">{{
                                cal_length(now.getTime() - start_time.getTime()) }}</p>
                        <!-- <p v-else style="font-size: 70px; font-weight:900; text-align: center;">0</p> -->
                        <hr>
                        <div class="text-center" style="margin-top: 3%; ">
                            <button v-if="!is_start" @click="start_func" class="btn btn-outline-warning "
                                style="border-radius: 0%;">开始</button>&nbsp;
                            <button v-else @click="stop_func" class="btn btn-outline-warning "
                                style="border-radius: 0%;">重置</button>&nbsp;
                            <button v-if="is_start" @click="end_func" class="btn btn-outline-info "
                                style="border-radius: 0%;">计次</button> &nbsp;


                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7" style="max-height: 70vh; overflow-y: auto;">
                <div class="card my-div-body">
                    <div class="card-body" style="display: flex; justify-content: space-between;">
                        <label class="text-warning " style="font-size:larger;font-weight: 900;">列表</label>
                        <button v-if="end_time.length > 0" @click="clear_func" class="btn btn-sm btn-outline-danger "
                            style="border-radius: 0%;">清空</button>

                    </div>


                </div>
                <div class="card my-div-body">

                    <div class="card-body" v-if="confirm_content.length > 0 && confirm_content != ''">
                        <label class="text-warning " style="font-size:larger;font-weight: 900;">计时 </label>
                        {{ confirm_content }}
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-borderless">

                        <thead>
                            <tr>
                                <th scope="col">


                                </th>
                            </tr>
                        </thead>
                        <!-- <tbody  >

                        <tr v-for="end in end_time" :key="end.id" >
                            <th scope="row"> -->
                        <div v-for="end in end_time" :key="end.id">
                            <div class="card opacity-100 my-div-body">
                                <div class="card-body">
                                    <p>
                                        <!-- <span class="">{{ end.id }}</span> -->
                                        <span class="">开始时间：</span>
                                        <span :class="'small '"><kbd>{{ start_time.toLocaleTimeString() }}</kbd>
                                            &nbsp;&nbsp;</span>
                                        <span class="">结束时间：</span>
                                        <span :class="'small ' "><kbd>{{ end.toLocaleTimeString() }}</kbd>
                                            &nbsp;&nbsp;</span>
                                        <span class="">持续时间：</span>
                                        <span :class="'small ' "><kbd>{{ cal_length(end.getTime() -
                                                start_time.getTime())}}</kbd> &nbsp;&nbsp;</span>
                                    </p>
                                    <p>

                                    </p>

                                </div>
                            </div>
                        </div>

                        <!-- </th>
                            
                        
                        
                        </tr>




                    </tbody> -->



                    </table>
                </div>


            </div>

        </div>


    </SpaceWar>
</template>
<script>

import SpaceWar from "@/components/post/SpaceWar.vue";
import { ref } from "vue";

// import router from "@/router/index"
export default {
    name: "TimerView",
    components: {
        SpaceWar: SpaceWar,
    },

    setup() {
        let confirm_content = ref("")
        let content = ref("")
        let is_start = ref("")
        let now_time = ref("")
        let start_time = ref("")
        let end_time = ref([])
        let start = ref("")
        let now = ref("")
        start_time.value = new Date()
        start.value = new Date().toLocaleTimeString()
        is_start.value = false
        // let end_list = ref("")

        setInterval(() => {
            now_time.value = new Date().toLocaleTimeString();
            now.value = new Date();
            // end_time.value = new Date(new Date().getTime() + timeLength.value * 60 * 1000).toLocaleTimeString();
        }, 10)


        const start_func = () => {
            // time_length.value = 0
            is_start.value = true
            confirm_content.value = content.value

            start_time.value = new Date();
            end_time.value = []
            start.value = new Date().toLocaleTimeString();

        }
        const stop_func = () => {
            confirm_content.value = ""
            is_start.value = false
        }
        const clear_func = () => {
            end_time.value = []
        }

        const end_func = () => {
            end_time.value.unshift(new Date())
        }

        const cal_length = (len) => {
            let res = ""
            let seconds = (len / 1000)

            let minutes = Math.floor(seconds / 60)
            let hours = Math.floor(minutes / 60)

            minutes = minutes - hours * 60
            seconds = seconds - hours * 60 * 60 - minutes * 60


            if (hours >= 1) {
                res += hours + " 小时"
            }
            if (minutes >= 1) {
                res += " " + minutes + " 分"
            }

            res += " " + seconds.toFixed(2) + " 秒"

            if (res == "") {
                res = " 0 秒"
            }
            return res

        }




        return {
            content,
            confirm_content,
            is_start,
            now,
            now_time,
            start_time,
            end_time,
            start,
            start_func,
            end_func,
            cal_length,
            stop_func,
            clear_func,

        }
    }


}

</script>
<style scoped>
td {
    font-size: large;
    font-weight: 600;
    font-family: 微软雅黑;
}

/* button{
    border-radius: 0%;
}
kbd{
    border-radius: 0%;
} */

.my-div-body {
    border-radius: 0%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;

    /* opacity: 0.97; */
    font-family: 微软雅黑;
}

.my-div-body:hover {
    /* border: 1px solid rgb(0, 0, 0); */
    background-color: rgba(255, 255, 255, 1);
    color: rgb(0, 0, 0);
}


label {
    font-family: 微软雅黑;
}

span {
    font-size: large;
    font-weight: 600;

    font-family: 微软雅黑;


}

div::-webkit-scrollbar {
    width: 8px;
    height: 1px;
}

div::-webkit-scrollbar-thumb {
    background: grey;
}

div::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px white;
}
</style>