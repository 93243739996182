import $ from 'jquery'
import store from '.';

export default ({
    state: {
        id: 0,
        username: "",
        avatar: "",
        bg: "",
        token: "",
        is_login: false,
        is_admin: false,
        proverb: "",
        email: "",
        phoneNumber: "",
    },
    getters: {
    },
    mutations: {
        updateUser(state, user) {
            state.id = user.userId;
            state.username = user.username;
            state.avatar = user.avatar;
            // state.token = user.token;
            state.is_login = user.is_login;
            state.is_admin = user.is_admin;
            state.proverb = user.proverb;
            state.email = user.email
            state.phoneNumber = user.phoneNumber
            state.bg = user.bg
        },

        updateToken(state, token) {
            state.token = token;
        },

        logout(state) {
            state.id = "";
            state.username = "";
            state.avatar = "";
            state.token = "";
            state.is_login = false;
            state.is_admin = false;
            state.proverb = "",
                state.email = ""
            state.phoneNumber = ""
        }



    },
    actions: {
        register(context, data) {

            $.ajaxSetup({
                traditional: true
            });


            $.ajax({
                url: store.state.BaseUrl + "/api/user/common/register",
                type: "POST",
                data: {
                    username: data.username,
                    password: data.password,
                    repeatPassword: data.repeatPassword
                },
                success(resp) {
                    if (resp.code == 1) {
                        console.log(resp)
                        //保存token并持久化
                        localStorage.setItem("my_jwt_token", resp.content.Authorization);
                        context.commit("updateToken", resp.content.Authorization);
                        //登录成功回调
                        data.success()

                    } else {
                        data.error(resp.content)
                        //登录失败，保持登出状态
                        context.commit("logout");
                    }

                },
                error() {
                    data.error("服务器未响应")
                    //登录失败，保持登出状态
                    context.commit("logout");
                }
            })
        },
        login(context, data) {
            $.ajax({
                url: store.state.BaseUrl + "/api/user/common/login",
                type: "POST",
                data: {
                    username: data.username,
                    password: data.password,
                },
                success(resp) {
                    if (resp.code == 1) {
                        console.log(resp)
                        //保存token并持久化
                        localStorage.setItem("my_jwt_token", resp.content.Authorization);
                        context.commit("updateToken", resp.content.Authorization);
                        //登录成功回调
                        data.success()

                    } else {
                        data.error(resp.content)
                        //登录失败，保持登出状态
                        context.commit("logout");
                    }

                },
                error() {
                    data.error("服务器未响应")
                    //登录失败，保持登出状态
                    context.commit("logout");
                }
            })
        },

        getInfo(context, data) {
            $.ajax({
                url: store.state.BaseUrl + "/api/user/",
                type: "GET",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    if (resp.code == 1) {

                        //0代表管理员
                        let is_admin = false;
                        // alert("管理员" + resp.content.roleId)
                        if (resp.content.roleId == 0) {
                            is_admin = true;
                        }

                        context.commit("updateUser", {
                            ...resp.content,
                            is_admin: is_admin,
                            is_login: true,
                        })
                        console.log(resp);
                        data.success();
                    } else {
                        data.error();
                    }
                },
                error() {
                    data.error();
                }
            })
        },
        logout(context, data) {
            localStorage.removeItem("my_jwt_token")
            context.commit("logout");
            data.success()
        }



    },
    modules: {

    }
})
