<template>
    <BlankBG>

        用户列表
    </BlankBG>
</template>
    
<script>

import BlankBG from "@/components/BlankBG.vue"

export default {
    name: "UserList",
    components: {
        BlankBG,
    }
}


</script>
    
<style scoped></style>